<template>
  <div>
    <div class="text-center">
      <div
        class="border border-gray-300 p-6 bg-white dark:bg-gray-800 shadow-lg rounded-md"
      >
        <h2 class="text-4xl font-extrabold dark:text-white pb-1">
          Créneaux disponibles - Permanences {{ calendarName }}
        </h2>

        <label
          class="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-100"
        >
          <span
            :class="{
              'bg-green-600 dark:bg-green-600': view === 'calendar',
              'dark:bg-gray-700': view !== 'calendar',
            }"
            class="px-4 py-2 border border-black rounded-l-md dark:border-white dark:bg-gray-700"
            @click="toggleView('calendar')"
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                fill="currentColor"
                d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01h1v-2h-1v2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01h1v-2h-1v2Z"
              ></path>
            </svg>
          </span>
          <span
            :class="{
              'bg-green-600 dark:bg-green-600': view === 'list',
              'dark:bg-gray-700': view !== 'list',
              'rounded-r-md': !isAdmin,
            }"
            class="px-4 py-2 border border-black dark:border-white dark:bg-gray-700"
            @click="toggleView('list')"
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              ></path>
            </svg>
          </span>

          <span
            v-if="isAdmin"
            :class="{
              'bg-green-600 dark:bg-green-600': view === 'form',
              'dark:bg-gray-700': view !== 'form',
            }"
            class="px-4 py-2 border border-black rounded-r-md dark:border-white dark:bg-gray-700"
            @click="toggleView('form')"
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 4v12m-6-6h12"
              ></path>
            </svg>
          </span>
        </label>

        <!-- DEBUT affichage calendrier  -->
        <div
          v-if="view === 'calendar'"
          class="viewCalendar sm:px-0 sm:py-0 lg:px-8 lg:pt-8"
        >
          <vue-cal
            class="custom-vue-cal"
            :events="styledEvents"
            :time="true"
            :week-view="true"
            locale="fr"
            @cell-click="isAdmin ? showAddSlotForm : null"
            @event-click="handleEventClick"
            :disable-views="['years', 'day', 'year']"
            :time-from="0 * 60"
            :time-to="24 * 60"
            :time-step="60"
            :time-cell-height="40"
            events-on-month-view="short"
          ></vue-cal>
        </div>
        <!-- FIN affichage calendrier  -->

        <!-- Affichage créneaux liste -->
        <div
          v-if="view === 'list'"
          class="viewList sm:px-0 sm:py-0 lg:px-8 lg:pt-8"
        >
          <div class="mb-4">
            <label for="filter" class="mr-2 dark:text-white">Afficher:</label>
            <select v-model="filterOption" @change="filterEvents">
              <option value="30days">Les 30 prochains jours</option>
              <option value="all">Tous les créneaux</option>
            </select>
          </div>
          <div class="overflow-x-auto">
            <table
              class="min-w-full divide-y divide-gray-200 dark:divide-gray-700 text-justify border"
            >
              <thead class="bg-gray-300 dark:bg-gray-800">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer dark:text-white"
                    @click="sortBy('title')"
                  >
                    Nom
                    <span v-if="sortKey === 'title'">{{
                      sortAsc ? "▲" : "▼"
                    }}</span>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer dark:text-white"
                    @click="sortBy('start')"
                  >
                    Date de début
                    <span v-if="sortKey === 'start'">{{
                      sortAsc ? "▲" : "▼"
                    }}</span>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer dark:text-white"
                    @click="sortBy('end')"
                  >
                    Date de fin
                    <span v-if="sortKey === 'end'">{{
                      sortAsc ? "▲" : "▼"
                    }}</span>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer dark:text-white"
                    @click="sortBy('startTime')"
                  >
                    Heure de début
                    <span v-if="sortKey === 'startTime'">{{
                      sortAsc ? "▲" : "▼"
                    }}</span>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer dark:text-white"
                    @click="sortBy('endTime')"
                  >
                    Heure de fin
                    <span v-if="sortKey === 'endTime'">{{
                      sortAsc ? "▲" : "▼"
                    }}</span>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer dark:text-white"
                    @click="sortBy('location')"
                  >
                    Lieu
                    <span v-if="sortKey === 'location'">{{
                      sortAsc ? "▲" : "▼"
                    }}</span>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer dark:text-white"
                    @click="sortBy('bookedBy')"
                  >
                    Inscrits
                    <span v-if="sortKey === 'bookedBy'">{{
                      sortAsc ? "▲" : "▼"
                    }}</span>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer dark:text-white"
                    @click="sortBy('maxInscrits')"
                  >
                    Max Inscrits
                    <span v-if="sortKey === 'maxInscrits'">{{
                      sortAsc ? "▲" : "▼"
                    }}</span>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider dark:text-white"
                  >
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody
                class="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700"
              >
                <template
                  v-for="(events, month) in groupEventsByMonth()"
                  :key="month"
                >
                  <tr class="bg-gray-200 dark:bg-gray-700">
                    <td
                      colspan="100%"
                      class="px-6 py-3 font-bold text-lg text-gray-700 dark:text-gray-300"
                    >
                      {{ month }}
                    </td>
                  </tr>
                  <tr
                    v-for="slot in events"
                    :key="slot.id"
                    :class="{
                      'bg-indigo-100 dark:bg-indigo-300':
                        editingSlot === slot.id,
                    }"
                  >
                    <td
                      data-label="Nom "
                      class="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-400"
                    >
                      <input
                        v-if="editingSlot === slot.id"
                        v-model="slot.title"
                        type="text"
                        class="w-full bg-indigo-50 dark:text-gray-900"
                      />
                      <span v-else>{{ slot.title }}</span>
                    </td>
                    <td
                      data-label="Date de début :"
                      class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                    >
                      <input
                        v-if="editingSlot === slot.id"
                        v-model="slot.startDate"
                        type="date"
                        class="w-full bg-indigo-50 text-gray-900 dark:text-gray-900"
                      />
                      <span v-else>{{
                        new Date(slot.start)
                          .toLocaleDateString("fr-FR", {
                            weekday: "long",
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .replace(/^\w/, (c) => c.toUpperCase())
                      }}</span>
                    </td>
                    <td
                      data-label="Date de fin :"
                      class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                    >
                      <input
                        v-if="editingSlot === slot.id"
                        v-model="slot.endDate"
                        type="date"
                        class="w-full bg-indigo-50 text-gray-900 dark:text-gray-900"
                      />
                      <span v-else>{{
                        new Date(slot.end)
                          .toLocaleDateString("fr-FR", {
                            weekday: "long",
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .replace(/^\w/, (c) => c.toUpperCase())
                      }}</span>
                    </td>
                    <td
                      data-label="Heure de début :"
                      class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                    >
                      <input
                        v-if="editingSlot === slot.id"
                        v-model="slot.startTime"
                        type="time"
                        class="w-full bg-indigo-50 text-gray-900 dark:text-gray-900"
                      />
                      <span v-else>{{
                        new Date(slot.start).toLocaleTimeString("fr-FR", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}</span>
                    </td>
                    <td
                      data-label="Heure de fin :"
                      class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                    >
                      <input
                        v-if="editingSlot === slot.id"
                        v-model="slot.endTime"
                        type="time"
                        class="w-full bg-indigo-50 text-gray-900 dark:text-gray-900"
                      />
                      <span v-else>{{
                        new Date(slot.end).toLocaleTimeString("fr-FR", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}</span>
                    </td>
                    <td
                      data-label="Lieu :"
                      class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                    >
                      <input
                        v-if="editingSlot === slot.id"
                        v-model="slot.location"
                        type="text"
                        class="w-full bg-indigo-50 dark:text-gray-900"
                      />
                      <span v-else>{{ slot.location || "N/A" }}</span>
                    </td>
                    <td
                      data-label="Inscrits :"
                      class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                    >
                      <ul>
                        <li v-for="userId in slot.bookedBy" :key="userId">
                          <span v-if="userNames[userId]">{{
                            userNames[userId]
                          }}</span>
                          <span v-else>Loading...</span>
                        </li>
                      </ul>
                    </td>
                    <td
                      data-label="Max inscrits :"
                      class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                    >
                      <input
                        v-if="editingSlot === slot.id"
                        v-model.number="slot.maxInscrits"
                        type="number"
                        class="w-full bg-indigo-50 text-gray-900 dark:text-gray-900"
                        min="1"
                      />
                      <span v-else>{{ slot.maxInscrits }}</span>
                    </td>

                    <!-- Boutons utilisateurs -->
                    <td
                      class="flex px-6 py-2 whitespace-nowrap text-sm font-medium"
                    >
                      <button
                        v-if="
                          !slot.bookedBy.includes(currentUserId) &&
                          slot.bookedBy.length < slot.maxInscrits
                        "
                        @click="bookSlot(slot.id)"
                        class="grow py-2 px-2 mx-1 text-sm font-semibold text-white text-center bg-teal-600 hover:bg-teal-700 focus:ring-2 focus:outline-none focus:ring-teal-500 rounded-md"
                      >
                        S'inscrire
                      </button>

                      <button
                        v-if="
                          !slot.bookedBy.includes(currentUserId) &&
                          slot.bookedBy.length >= slot.maxInscrits &&
                          slot.maxInscrits != 0
                        "
                        class="grow py-2 px-2 mx-1 text-sm font-semibold text-white text-center uppercase bg-rose-400 rounded-md"
                        disabled
                      >
                        Complet
                      </button>

                      <button
                        v-if="slot.maxInscrits == 0"
                        class="grow py-2 px-2 mx-1 text-sm font-semibold text-white text-center uppercase bg-rose-600 rounded-md"
                        disabled
                      >
                        Annulé
                      </button>

                      <button
                        v-if="slot.bookedBy.includes(currentUserId)"
                        @click="unbookSlot(slot.id)"
                        class="grow py-2 px-2 mx-1 text-sm font-semibold text-white text-center bg-amber-500 hover:bg-amber-600 focus:ring-2 focus:outline-none focus:ring-amber-400 rounded-lg"
                      >
                        Se désinscrire
                      </button>

                      <!-- Boutons ADMIN -->
                      <button
                        v-if="isAdmin && editingSlot !== slot.id"
                        @click="startEditing(slot)"
                        class="shrink py-2 px-2 mx-1 text-sm font-semibold text-white text-center bg-indigo-500 hover:bg-indigo-600 focus:ring-2 focus:outline-none focus:ring-indigo-400 rounded-lg"
                      >
                        Modifier
                      </button>
                      <button
                        v-if="isAdmin && editingSlot === slot.id"
                        @click="updateSlot(slot)"
                        class="shrink py-2 px-2 mx-1 text-sm font-semibold text-white text-center bg-indigo-700 hover:bg-indigo-800 focus:ring-2 focus:outline-none focus:ring-indigo-600 rounded-lg"
                      >
                        Sauvegarder
                      </button>
                      <button
                        v-if="isAdmin && editingSlot === slot.id"
                        @click="cancelEditing"
                        class="shrink py-2 px-2 mx-1 text-sm font-semibold text-white text-center bg-gray-600 hover:bg-gray-700 focus:ring-2 focus:outline-none focus:ring-primary-300 rounded-lg"
                      >
                        Annuler
                      </button>
                      <button
                        v-if="isAdmin"
                        @click="deleteEvent(slot.id)"
                        class="shrink py-2 px-2 mx-1 text-sm font-semibold text-white text-center bg-red-600 hover:bg-red-700 focus:ring-2 focus:outline-none focus:ring-red-500 rounded-lg"
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <!--  ADMIN ////////////////////////////////////////////////////////////////////////////////////////// -->
        <!-- Formulaire ajout créneaux -->
        <div
          v-if="view === 'form' && isAdmin && newSlot.maxInscrits !== null"
          class="addShift sm:px-0 sm:py-0 lg:px-8 lg:pt-8"
        >
          <h2 class="text-3xl font-extrabold dark:text-white">
            Ajouter un créneau
          </h2>
          <div class="flex items-center justify-center p-12">
            <div class="mx-auto w-full max-w-[550px]">
              <div class="-mx-3 flex flex-wrap">
                <div class="basis-full w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      class="mb-1 block text-base font-medium text-gray-600 text-left"
                      >Titre</label
                    >
                    <input
                      v-model="newSlot.title"
                      type="text"
                      placeholder="Titre"
                      class="w-full rounded-md border border-gray-300 bg-white py-3 px-6 text-base font-medium text-gray-600 outline-none focus:focus:focus:border-indigo-600 focus:shadow-md"
                    />
                  </div>
                </div>

                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      class="mb-1 block text-base font-medium text-gray-600 text-left"
                      >Lieu</label
                    >
                    <input
                      v-model="newSlot.location"
                      type="text"
                      placeholder="Lieu (optionnel)"
                      class="w-full rounded-md border border-gray-300 bg-white py-3 px-6 text-base font-medium text-gray-600 outline-none focus:border-indigo-600 focus:shadow-md"
                    />
                  </div>
                </div>

                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      class="mb-1 block text-base font-medium text-gray-600 text-left"
                      >Nombre max d'inscrits</label
                    >
                    <input
                      v-model.number="newSlot.maxInscrits"
                      type="number"
                      min="0"
                      placeholder=""
                      class="w-full rounded-md border border-gray-300 bg-white py-3 px-6 text-base font-medium text-gray-600 outline-none focus:border-indigo-600 focus:shadow-md"
                    />
                  </div>
                </div>
              </div>
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      class="mb-1 block text-base font-medium text-gray-600 text-left"
                      >Date de début</label
                    >
                    <input
                      v-model="newSlot.startDate"
                      type="date"
                      placeholder=""
                      class="w-full rounded-md border border-gray-300 bg-white py-3 px-6 text-base font-medium text-gray-600 outline-none focus:border-indigo-600 focus:shadow-md"
                    />
                  </div>
                </div>

                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      class="mb-1 block text-base font-medium text-gray-600 text-left"
                      >Date de fin</label
                    >
                    <input
                      v-model="newSlot.endDate"
                      type="date"
                      placeholder=""
                      class="w-full rounded-md border border-gray-300 bg-white py-3 px-6 text-base font-medium text-gray-600 outline-none focus:border-indigo-600 focus:shadow-md"
                    />
                  </div>
                </div>
              </div>
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      class="mb-1 block text-base font-medium text-gray-600 text-left"
                      >Heure de début</label
                    >
                    <input
                      v-model="newSlot.startTime"
                      type="time"
                      placeholder=""
                      class="w-full rounded-md border border-gray-300 bg-white py-3 px-6 text-base font-medium text-gray-600 outline-none focus:border-indigo-600 focus:shadow-md"
                    />
                  </div>
                </div>

                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      class="mb-1 block text-base font-medium text-gray-600 text-left"
                      >Heure de fin</label
                    >
                    <input
                      v-model="newSlot.endTime"
                      type="time"
                      placeholder=""
                      class="w-full rounded-md border border-gray-300 bg-white py-3 px-6 text-base font-medium text-gray-600 outline-none focus:border-indigo-600 focus:shadow-md"
                    />
                  </div>
                </div>
              </div>
              <div class="-mx-3 flex flex-wrap">
                <div class="grow px-3 sm:w-1/2">
                  <label class="block text-base font-medium text-gray-600"
                    >Récurrence</label
                  >
                  <select
                    v-model="newSlot.recurrence"
                    class="w-full rounded-md border bg-white py-2 px-3"
                  >
                    <option value="none">Pas de récurrence</option>
                    <option value="daily">Quotidien</option>
                    <option value="weekly">Hebdomadaire</option>
                    <!-- <option value="monthly">Mensuel</option> -->
                  </select>
                </div>

                <div
                  class="w-full px-3 sm:w-1/2"
                  v-if="newSlot.recurrence !== 'none'"
                >
                  <label class="block text-base font-medium text-gray-600"
                    >Nombre de répétitions</label
                  >
                  <input
                    type="number"
                    v-model.number="newSlot.recurrenceCount"
                    min="1"
                    class="w-full rounded-md border bg-white py-2 px-3"
                  />
                </div>
              </div>
              <div class="pt-4 flex items-center space-x-4">
                <button
                  @click="addSlot"
                  class="w-full text-white bg-teal-600 hover:bg-teal-700 focus:ring-2 focus:outline-none focus:ring-teal-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mr-2 mb-2"
                >
                  {{ buttonText }}
                </button>
                <button
                  @click="cancelAddSlot"
                  class="w-full text-white bg-rose-600 hover:bg-rose-700 focus:ring-2 focus:outline-none focus:ring-rose-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mr-2 mb-2"
                >
                  Annuler
                </button>
              </div>
              <p v-if="messageConfirmation" class="mt-4 text-green-600">
                {{ messageConfirmation }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import { useModal } from "vue-final-modal";
import EventDetailsModal from "./EventDetailsModal.vue";
import { getDoc, doc } from "firebase/firestore"; // Ajout des importations nécessaires
import { db } from "../firebaseConfig"; // Importer l'objet db depuis la configuration Firebase
import { getAuth } from "firebase/auth";
import { mapActions } from "vuex"; // Importer mapActions pour utiliser les actions du store
import {
  initializeUserRole,
  getUserDisplayName as fetchUserDisplayName,
} from "../services/servicesPlanning.js";

export default {
  components: { VueCal },
  props: {
    calendarId: {
      type: String,
      required: true,
    },
    calendarName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      events: [],
      isAdmin: false,
      view: localStorage.getItem("view") || "calendar", // Utiliser la vue sauvegardée
      filterOption: "30days", // Default filter option
      newSlot: {
        title: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        location: "",
        maxInscrits: null,
        recurrence: "none", // 'daily', 'weekly', 'monthly'
        recurrenceCount: 1, // Combien de fois l'événement doit se répéter
      },
      defaultMaxInscrits: 10,
      selectedEvent: {},
      currentUserEmail: "",
      currentUserId: "",
      userNames: {},
      editingSlot: null,
      sortKey: "start", // clé de tri
      sortAsc: true, // ordre de tri, true pour ascendant, false pour descendant
      messageConfirmation: "", // Propriété pour le message de confirmation d'ajout
      loadingUserNames: {}, // État de chargement pour les noms des utilisateurs
    };
  },
  computed: {
    styledEvents() {
      return this.events.map((event) => {
        const participants = event.bookedBy
          .map((userId) => {
            const name = this.userNames[userId] || "Loading...";
            const initials = name
              .split(" ")
              .map((n) => n[0].toUpperCase())
              .join("");
            return `<span class="participant-initials" title="${name}">${initials}</span>`;
          })
          .join(" ");

        let eventClass = "custom-event";
        if (event.maxInscrits === 0) {
          eventClass += " event-cancel";
        } else if (event.bookedBy.length === 0 && event.maxInscrits != 0) {
          eventClass += " event-empty";
        } else if (event.bookedBy.length < event.maxInscrits) {
          eventClass += " event-partial";
        } else if (event.bookedBy.length >= event.maxInscrits) {
          eventClass += " event-full";
        }

        return {
          ...event,
          class: eventClass,
          start: new Date(event.start), // Assurez-vous que les dates sont des objets Date
          end: new Date(event.end), // Assurez-vous que les dates sont des objets Date
          content: `${participants}`,
        };
      });
    },
    filteredEvents() {
      const now = new Date();
      const next30Days = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);
      return this.events.filter((event) => {
        const eventDate = new Date(event.start);
        if (this.filterOption === "30days") {
          return eventDate <= next30Days && eventDate >= now;
        }
        return eventDate >= now;
      });
    },
    buttonText() {
      return this.newSlot.recurrence === "none"
        ? "Ajouter le créneau"
        : "Ajouter les créneaux";
    },
  },
  async mounted() {
    await this.checkUserRole();
    await this.fetchCalendarMaxInscrits();
    this.newSlot.maxInscrits = this.defaultMaxInscrits;
    await this.fetchEvents();
    const user = getAuth().currentUser;
    if (user) {
      this.currentUserEmail = user.email;
      this.currentUserId = user.uid;
    }
    this.sortEvents(); // tri par défaut sur "Date de début"
  },
  watch: {
    calendarId: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          await this.fetchCalendarMaxInscrits();
          this.newSlot.maxInscrits = this.defaultMaxInscrits;
          await this.fetchEvents();
          await this.checkUserRole();
        }
      },
    },
    view(newVal) {
      localStorage.setItem("view", newVal); // Stocker l'état dans localStorage
    },
    // Surveillance des changements de la date de début
    "newSlot.startDate": function (newDate) {
      if (newDate) {
        this.newSlot.endDate = newDate; // Définir la date de fin égale à la date de début
      }
    },
    // Surveillance des changements de l'heure de début
    "newSlot.startTime": function (newTime) {
      if (newTime) {
        const startTime = new Date(`1970-01-01T${newTime}:00`);
        startTime.setHours(startTime.getHours() + 2); // Ajouter deux heures
        let hours = startTime.getHours().toString().padStart(2, "0");
        let minutes = startTime.getMinutes().toString().padStart(2, "0");
        this.newSlot.endTime = `${hours}:${minutes}`; // Mettre à jour l'heure de fin
      }
    },
  },
  methods: {
    ...mapActions(["bookSlot", "unbookSlot", "updateSlot"]),
    toggleView(view) {
      this.view = view;
    },
    async fetchEvents() {
      await this.$store.dispatch("fetchSlots", this.calendarId);
      this.events = this.$store.state.slots.filter(
        (slot) => slot.calendar === this.calendarId
      );
      await this.loadUserNames();
      this.sortEvents(); // Trier les événements après récupération
      this.$emit("slot-updated");
    },
    async loadUserNames() {
      for (const event of this.events) {
        for (const userId of event.bookedBy) {
          if (!this.userNames[userId] && !this.loadingUserNames[userId]) {
            this.loadingUserNames[userId] = true; // Marquer l'utilisateur comme en cours de chargement
            this.userNames[userId] = await fetchUserDisplayName(userId);
            delete this.loadingUserNames[userId]; // Supprimer le marquage une fois le chargement terminé
          }
        }
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        weekday: "long",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
      const formattedDate = date.toLocaleDateString("fr-FR", options);

      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    },
    async checkUserRole() {
      const userRole = await initializeUserRole(this.calendarId);
      this.isAdmin = userRole.isAdmin;
    },
    showAddSlotForm({ start }) {
      this.newSlot.startDate = start ? start.split("T")[0] : "";
      this.newSlot.endDate = this.newSlot.startDate;
      this.newSlot.startTime = "";
      this.newSlot.endTime = "";
      this.newSlot.location = "";
      this.newSlot.maxInscrits = this.defaultMaxInscrits; // Utiliser la valeur par défaut
      this.$forceUpdate();
    },
    async addSlot() {
      const {
        title,
        startTime,
        endTime,
        startDate,
        endDate,
        location,
        maxInscrits,
        recurrence,
        recurrenceCount,
      } = this.newSlot;

      if (title && startTime && endTime && startDate && endDate) {
        if (this.validateSlotDates()) {
          let currentStartDate = new Date(`${startDate}T${startTime}`);
          let currentEndDate = new Date(`${endDate}T${endTime}`);

          for (let i = 0; i < recurrenceCount; i++) {
            const startDateTime = `${currentStartDate.getFullYear()}-${(
              currentStartDate.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${currentStartDate
              .getDate()
              .toString()
              .padStart(2, "0")}T${currentStartDate
              .getHours()
              .toString()
              .padStart(2, "0")}:${currentStartDate
              .getMinutes()
              .toString()
              .padStart(2, "0")}`;
            const endDateTime = `${currentEndDate.getFullYear()}-${(
              currentEndDate.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${currentEndDate
              .getDate()
              .toString()
              .padStart(2, "0")}T${currentEndDate
              .getHours()
              .toString()
              .padStart(2, "0")}:${currentEndDate
              .getMinutes()
              .toString()
              .padStart(2, "0")}`;

            const newEvent = {
              title,
              start: startDateTime,
              end: endDateTime,
              calendar: this.calendarId,
              location: location || "",
              bookedBy: [],
              maxInscrits: maxInscrits,
            };

            await this.$store.dispatch("addSlot", newEvent);
            this.events.push({
              id: newEvent.id,
              start: startDateTime,
              end: endDateTime,
              title,
              calendar: this.calendarId,
              location: location || "",
              bookedBy: [],
              maxInscrits: maxInscrits,
            });

            switch (recurrence) {
              case "daily":
                currentStartDate.setDate(currentStartDate.getDate() + 1);
                currentEndDate.setDate(currentEndDate.getDate() + 1);
                break;
              case "weekly":
                currentStartDate.setDate(currentStartDate.getDate() + 7);
                currentEndDate.setDate(currentEndDate.getDate() + 7);
                break;
              case "monthly":
                currentStartDate.setMonth(currentStartDate.getMonth() + 1);
                currentEndDate.setMonth(currentEndDate.getMonth() + 1);
                break;
            }
          }
          this.messageConfirmation =
            recurrence === "none"
              ? "Créneau ajouté avec succès !"
              : "Créneaux ajoutés avec succès !";
          this.fetchEvents();
          this.$emit("slot-updated");
        }
      } else {
        alert("Veuillez remplir tous les champs");
      }
    },
    cancelAddSlot() {
      this.newSlot = {
        title: "",
        date: "",
        startTime: "",
        endTime: "",
        maxInscrits: this.defaultMaxInscrits,
        recurrence: "none", // Réinitialiser la récurrence à "none"
        recurrenceCount: 1, // Réinitialiser le nombre de répétitions à 1
      };
    },
    validateSlotDates() {
      const startDate = new Date(this.newSlot.startDate);
      const endDate = new Date(this.newSlot.endDate);

      if (endDate < startDate) {
        alert("La date de fin ne peut pas être antérieure à la date de début.");
        return false;
      }
      return true;
    },
    async fetchCalendarMaxInscrits() {
      const calendarDoc = await getDoc(doc(db, "calendars", this.calendarId));
      if (calendarDoc.exists()) {
        const calendarData = calendarDoc.data();
        this.defaultMaxInscrits = calendarData.maxInscrits; // Utiliser la valeur par défaut si non spécifiée
      } else {
        this.defaultMaxInscrits = 10; // Utiliser 10 si le document n'existe pas
      }
      //console.log("defaultMaxInscrits:", this.defaultMaxInscrits);
    },
    async handleEventClick(event) {
      const modal = useModal({
        component: EventDetailsModal,
        attrs: {
          selectedEvent: event,
          isAdmin: this.isAdmin,
          currentUserEmail: this.currentUserEmail,
          currentUserId: this.currentUserId,
          deleteEvent: this.deleteEvent,
          bookSlot: this.bookSlot,
          unbookSlot: this.unbookSlot,
          closeModal: () => {
            modal.close();
            this.fetchEvents(); // Rafraîchir les événements après la fermeture du modal
          },
        },
        listeners: {
          "event-updated": () => {
            this.fetchEvents(); // Rafraîchir les événements après la mise à jour
          },
        },
      });
      this.modalInstance = modal;
      await modal.open(); // Open modal only after it's ready
    },
    async deleteEvent(eventId) {
      if (confirm(`Voulez-vous supprimer cet événement ?`)) {
        await this.$store.dispatch("deleteSlot", eventId);
        this.events = this.events.filter((e) => e.id !== eventId);
        this.fetchEvents();
        this.$emit("slot-updated");
        this.closeModal();
      }
    },
    async bookSlot(eventId) {
      await this.$store.dispatch("bookSlot", {
        eventId,
        userId: this.currentUserId,
      });
      this.fetchEvents();
      this.$emit("slot-updated");
      this.closeModal();
    },
    async unbookSlot(eventId) {
      await this.$store.dispatch("unbookSlot", {
        eventId,
        userId: this.currentUserId,
      });
      this.fetchEvents();
      this.$emit("slot-updated");
      this.closeModal();
    },
    startEditing(slot) {
      this.editingSlot = slot.id;

      if (slot.start instanceof Date && slot.end instanceof Date) {
        slot.startDate = slot.start.toISOString().split("T")[0]; // yyyy-mm-dd
        slot.startTime = slot.start.toTimeString().split(" ")[0].slice(0, 5); // hh:mm
        slot.endDate = slot.end.toISOString().split("T")[0]; // yyyy-mm-dd
        slot.endTime = slot.end.toTimeString().split(" ")[0].slice(0, 5); // hh:mm
      } else {
        const startParts = slot.start.split("T");
        slot.startDate = startParts[0];
        slot.startTime = startParts[1].slice(0, 5);

        const endParts = slot.end.split("T");
        slot.endDate = endParts[0];
        slot.endTime = endParts[1].slice(0, 5);
      }

      this.selectedEvent = {
        ...slot,
      };
    },
    cancelEditing() {
      this.editingSlot = null;
      this.fetchEvents(); // Recharger les événements pour annuler les modifications
    },
    async updateSlot(slot) {
      let startDate, startTime, endDate, endTime;

      // Si les champs sont déjà fournis, les utiliser tels quels, sinon extraire à partir de `slot.start` et `slot.end`
      if (slot.startDate && slot.startTime) {
        startDate = slot.startDate;
        startTime = slot.startTime;
      } else {
        const [date, time] = slot.start.split("T");
        startDate = date;
        startTime = time;
      }

      if (slot.endDate && slot.endTime) {
        endDate = slot.endDate;
        endTime = slot.endTime;
      } else {
        const [date, time] = slot.end.split("T");
        endDate = date;
        endTime = time;
      }

      const updatedSlot = {
        title: slot.title,
        start: `${startDate}T${startTime}`,
        end: `${endDate}T${endTime}`,
        location: slot.location || "",
        bookedBy: slot.bookedBy || [],
        maxInscrits:
          slot.maxInscrits !== undefined
            ? slot.maxInscrits
            : this.defaultMaxInscrits,
      };

      // Remove any undefined fields before updating the document
      Object.keys(updatedSlot).forEach((key) => {
        if (updatedSlot[key] === undefined) {
          delete updatedSlot[key];
        }
      });

      await this.$store.dispatch("updateSlot", { id: slot.id, ...updatedSlot });
      this.editingSlot = null;
      this.fetchEvents();
      alert("Créneau mis à jour avec succès");
      this.$emit("slot-updated");
    },
    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.close();
      }
    },
    filterEvents() {
      this.$forceUpdate();
    },
    sortEvents() {
      this.events.sort((a, b) => {
        if (a[this.sortKey] < b[this.sortKey]) return this.sortAsc ? -1 : 1;
        if (a[this.sortKey] > b[this.sortKey]) return this.sortAsc ? 1 : -1;
        return 0;
      });
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortKey = key;
        this.sortAsc = true;
      }
      this.sortEvents();
    },
    groupEventsByMonth() {
      const groupedEvents = this.filteredEvents.reduce((acc, event) => {
        const month = new Date(event.start).toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        if (!acc[month]) {
          acc[month] = [];
        }
        acc[month].push(event);
        return acc;
      }, {});
      return groupedEvents;
    },
  },
};
</script>
