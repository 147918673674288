import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBgZmBxMqWaVmYB1Tsf1e4fRwv3heE_Qro",
    authDomain: "planning-app-ff5f3.firebaseapp.com",
    projectId: "planning-app-ff5f3",
    storageBucket: "planning-app-ff5f3.appspot.com",
    messagingSenderId: "720963274593",
    appId: "1:720963274593:web:8918cf75feeaedc0d3e720",
    measurementId: "G-XETFYHXLEV"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Définir la persistance de session sur "local"
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Persistence de session configurée avec succès
    //console.log('Persistence de session configurée avec succès');
  })
  .catch((error) => {
    console.error('Erreur lors de la configuration de la persistance de session:', error);
  });

export { auth, db, storage };