// servicesPlanning.js
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebaseConfig";
import { getAuth } from "firebase/auth";
import store from '../services/store';

// Récupérer les noms des calendriers avec le cache
export const getCalendarNames = async () => {
  if (store.state.calendars.length > 0) {
    return store.state.calendars;
  }
  const calendarNames = [];
  const querySnapshot = await getDocs(collection(db, "calendars"));
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      const data = doc.data();
      if (data.name) {
        calendarNames.push({
          id: doc.id,
          name: data.name,
        });
      }
    }
  });
  store.commit('setCalendars', calendarNames); // Mettre à jour le cache
  return calendarNames;
};

// Récupérer les rôles d'un utilisateur avec le cache
export const fetchUserRoles = async (userId) => {
  const cachedUser = store.state.currentUser;
  if (cachedUser && cachedUser.id === userId && cachedUser.roles) {
    return cachedUser.roles;
  }
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const roles = docSnap.data().roles || [];
    store.commit('setCurrentUser', { ...cachedUser, roles });  // Mettre à jour le cache avec les rôles
    return roles;
  }
  return [];
};

// Vérifier si l'utilisateur est administrateur
export const checkAdminRole = async (userId) => {
  const roles = await fetchUserRoles(userId);
  return roles.includes("admin");
};

// Initialiser le rôle de l'utilisateur pour un calendrier
export const initializeUserRole = async (calendarId) => {
  const user = getAuth().currentUser;
  if (user) {
    const roles = await fetchUserRoles(user.uid);
    return {
      email: user.email,
      isAdmin: roles.includes("admin") || (calendarId ? roles.includes(`ecriture_${calendarId}`) : false),
    };
  }
  return {
    email: null,
    isAdmin: false,
  };
};

// Vérifier si l'utilisateur a un accès en écriture à un calendrier quelconque
export const hasWriteAccessToAnyCalendar = async () => {
  const user = getAuth().currentUser;
  if (user) {
    const roles = await fetchUserRoles(user.uid);
    return roles.includes("admin") || roles.some(role => role.startsWith('ecriture_'));
  }
  return false;
};

// Récupérer les événements d'un calendrier spécifique avec le cache
export const fetchEventsByCalendar = async (calendarId) => {
  if (store.state.slots.length > 0) {
    return store.state.slots.filter(slot => slot.calendar === calendarId);
  }
  const events = [];
  const querySnapshot = await getDocs(collection(db, "slots"));
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (data.calendar === calendarId) {
      events.push({ ...data, id: doc.id });
    }
  });
  store.commit('setSlots', events); // Mettre à jour le cache
  return events;
};

// Récupérer les détails d'un utilisateur avec le cache
export const fetchUserDetails = async (userId) => {
  const cachedUser = store.state.users.find(user => user.id === userId);
  if (cachedUser) {
    return cachedUser;
  }
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const userData = docSnap.data();
    const userDetails = {
      uid: userId,
      displayName: userData.firstName && userData.lastName
        ? `${userData.firstName} ${userData.lastName.charAt(0)}.`
        : userId,
    };
    store.commit('setUsers', [...store.state.users, userDetails]); // Mettre à jour le cache
    return userDetails;
  }
  return { uid: userId };
};

// Récupérer l'image de profil d'un utilisateur
export const fetchUserProfileImage = async (userId) => {
  const userFileRef = storageRef(storage, `profileImages/${userId}`);
  const defaultFileRef = storageRef(storage, `profileImages/default.jpg`);

  try {
    return await getDownloadURL(userFileRef);
  } catch (error) {
    console.error("Error fetching profile image, loading default image:", error);
    try {
      return await getDownloadURL(defaultFileRef);
    } catch (defaultError) {
      console.error("Error fetching default profile image:", defaultError);
      return "";
    }
  }
};

// Rediriger vers le premier calendrier accessible
export const redirectToFirstAccessibleCalendar = async (userId, next) => {
  try {
    const roles = await fetchUserRoles(userId);
    const calendars = await getCalendarNames();

    const firstAccessibleCalendar = calendars.find(calendar =>
      roles.includes("admin") || roles.includes(`lecture_${calendar.id}`)
    );

    if (firstAccessibleCalendar) {
      next(`/calendar/${firstAccessibleCalendar.id}`);
    } else {
      next("/no-access");
    }
  } catch (error) {
    next("/login");
  }
};

// Récupérer le nom d'affichage d'un utilisateur avec le cache
export const getUserDisplayName = async (userId) => {
  // Vérifier si l'utilisateur est dans le cache
  const cachedUser = store.state.users.find(user => user.id === userId);
  if (cachedUser) {
    return `${cachedUser.firstName} ${cachedUser.lastName.charAt(0)}.`;
  }

  // Si l'utilisateur n'est pas dans le cache, le récupérer depuis la base de données
  const userDoc = await getDoc(doc(db, "users", userId));
  if (userDoc.exists()) {
    const userData = userDoc.data();
    const { firstName, lastName } = userData;
    return `${firstName} ${lastName.charAt(0)}.`;
  } else {
    return "Unknown User";
  }
}

// Récupérer le nom complet d'un utilisateur avec le cache
export const getUserFullName = async (userId) => {
  // Vérifier si l'utilisateur est dans le cache
  const cachedUser = store.state.users.find(user => user.id === userId);
  if (cachedUser) {
    return `${cachedUser.firstName} ${cachedUser.lastName}`;
  }

  // Si l'utilisateur n'est pas dans le cache, le récupérer depuis la base de données
  const userDoc = await getDoc(doc(db, "users", userId));
  if (userDoc.exists()) {
    const userData = userDoc.data();
    const { firstName, lastName } = userData;
    return `${firstName} ${lastName}`;
  } else {
    return "Unknown User";
  }
}
