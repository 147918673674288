import { createI18n } from 'vue-i18n';

const messages = {
  fr: {
    // Ajoutez vos traductions personnalisées ici si nécessaire
    // Par exemple: 'eventTitle': 'Titre de l\'événement'
  }
};

const i18n = createI18n({
  locale: 'fr', // Définit la langue par défaut
  messages,
});

export default i18n;
