<template>
  <VueFinalModal
    class="flex justify-center items-center text-white"
    content-class="flex flex-col max-w-xl mx-4 p-4 bg-white text-gray-800 dark:text-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2"
  >
    <h3 class="text-xl text-center">{{ isEditing ? editableEvent.title : selectedEvent.title }}</h3>
    <div v-if="isEditing">
      <label>
        Titre:
        <input v-model="editableEvent.title" type="text" class="w-full bg-blue-500 text-gray-900 dark:text-gray-100" />
      </label>
      <label>
        Début:
        <input v-model="editableEvent.start" type="datetime-local" class="w-full bg-blue-500 text-gray-900 dark:text-gray-100" />
      </label>
      <label>
        Fin:
        <input v-model="editableEvent.end" type="datetime-local" class="w-full bg-blue-500 text-gray-900 dark:text-gray-100" />
      </label>
      <label>
        Lieu:
        <input v-model="editableEvent.location" type="text" class="w-full bg-blue-500 text-gray-900 dark:text-gray-100" />
      </label>
      <label>
        Nombre maximum d'inscrits :
        <input
          v-model.number="editableEvent.maxInscrits"
          type="number"
          min="0"
          class="w-full bg-blue-500 text-gray-900 dark:text-gray-100"
        />
      </label>
    </div>
    <div v-else>
      <p><strong>Début:</strong> {{ formatDate(selectedEvent.start) }}</p>
      <p><strong>Fin:</strong> {{ formatDate(selectedEvent.end) }}</p>
      <p><strong>Lieu :</strong> {{ selectedEvent.location }}</p>
      <p><strong>Inscrits:</strong></p>
      <ul>
        <li v-for="user in bookedUsers" :key="user.uid">
          {{ user.displayName || user.uid }}
        </li>
      </ul>
      <p><strong>Nombre maximum d'inscrits :</strong> {{ selectedEvent.maxInscrits }}</p>
    </div>
    <button v-if="canDeleteEvent" @click="deleteEvent(selectedEvent.id)" class="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
      Supprimer
    </button>
    <button v-if="!selectedEvent.bookedBy.includes(currentUserId) && selectedEvent.bookedBy.length < selectedEvent.maxInscrits" @click="bookSlot(selectedEvent.id)" class="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
      S'inscrire
    </button>
    <button v-if="!selectedEvent.bookedBy.includes(currentUserId) && selectedEvent.bookedBy.length >= selectedEvent.maxInscrits && selectedEvent.maxInscrits != 0" class="text-white bg-yellow-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center" disabled>
      COMPLET
    </button>
    <button v-if="selectedEvent.maxInscrits == 0" class="text-white bg-pink-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center" disabled>
      ANNULE
    </button>
    <button v-if="selectedEvent.bookedBy.includes(currentUserId)" @click="unbookSlot(selectedEvent.id)" class="text-white bg-yellow-600 hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
      Se désinscrire
    </button>
    <button v-if="canDeleteEvent && !isEditing" @click="startEditing" class="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
      Modifier
    </button>
    <button v-if="canDeleteEvent && isEditing" @click="updateEvent" class="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
      Sauvegarder
    </button>
    <button v-if="canDeleteEvent && isEditing" @click="cancelEditing" class="text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
      Annuler
    </button>
    <button @click="closeModal" class="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
      Fermer
    </button>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { fetchUserRoles } from "../services/servicesPlanning.js";

export default {
  components: {
    VueFinalModal,
  },
  props: {
    selectedEvent: Object,
    currentUserEmail: String,
    currentUserId: String,
    deleteEvent: Function,
    bookSlot: Function,
    unbookSlot: Function,
    closeModal: Function,
  },
  data() {
    return {
      bookedUsers: [],
      isLoading: true,
      userRoles: [],
      canDeleteEvent: false,
      isEditing: false,
      editableEvent: {},
    };
  },
  async mounted() {
    this.userRoles = await fetchUserRoles(this.currentUserId);
    await this.fetchBookedUsers();
    this.checkDeletePermission();
  },
  watch: {
    selectedEvent: {
      immediate: true,
      handler() {
        this.initializeEditableEvent();
      },
    },
  },
  methods: {
    async fetchUserDetails(userId) {
      try {
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          return {
            uid: userId,
            displayName:
              userData.firstName && userData.lastName
                ? `${userData.firstName} ${userData.lastName.charAt(0)}.`
                : userId,
          };
        } else {
          return { uid: userId };
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        return { uid: userId };
      }
    },
    async fetchBookedUsers() {
      const users = await Promise.all(
        this.selectedEvent.bookedBy.map(async (userId) => {
          try {
            const userDetails = await this.fetchUserDetails(userId);
            return userDetails;
          } catch (error) {
            console.error("Error fetching user details:", error);
            return { uid: userId };
          }
        })
      );
      this.bookedUsers = users;
      this.isLoading = false;
    },
    checkDeletePermission() {
      const calendarId = this.selectedEvent.calendar;
      this.canDeleteEvent =
        this.userRoles.includes("admin") ||
        this.userRoles.includes(`ecriture_${calendarId}`);
    },
    startEditing() {
      this.isEditing = true;
    },
    cancelEditing() {
      this.isEditing = false;
      this.initializeEditableEvent();
    },
    async updateEvent() {
      const eventRef = doc(db, "slots", this.selectedEvent.id);
      const updatedEvent = {
        title: this.editableEvent.title,
        start: this.editableEvent.start,
        end: this.editableEvent.end,
        location: this.editableEvent.location,
        calendar: this.selectedEvent.calendar,
        bookedBy: this.selectedEvent.bookedBy,
        maxInscrits: this.editableEvent.maxInscrits, // Include maxInscrits in the update
      };
      await updateDoc(eventRef, updatedEvent);
      this.$emit("event-updated", updatedEvent); // Emit event-updated with updatedEvent
      this.isEditing = false;
      this.closeModal();
    },
    initializeEditableEvent() {
      this.editableEvent = {
        ...this.selectedEvent,
        start: this.formatDateToLocal(this.selectedEvent.start),
        end: this.formatDateToLocal(this.selectedEvent.end),
        maxInscrits: this.selectedEvent.maxInscrits, // Initialize maxInscrits
      };
    },
    formatDateToLocal(dateStr) {
      const date = new Date(dateStr);
      const tzoffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
      const localISOTime = new Date(date - tzoffset).toISOString().slice(0, -1);
      return localISOTime.substring(0, 16);
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleString();
    },
  },
};
</script>
