import { createApp } from 'vue';
import App from './App.vue';
import createRouterWithDynamicRoutes from './router';
import i18n from './i18n';
import { createVfm } from 'vue-final-modal';
import store from './services/store'; // Importez le store Vuex

// Importez les styles de TailwindCSS
import './assets/tailwind.css';

// Importez les styles perso
import './assets/style.css';

// Importez les styles de vue-final-modal
import 'vue-final-modal/style.css';

const app = createApp(App);
const vfm = createVfm();

createRouterWithDynamicRoutes().then(router => {
  app.use(router);
  app.use(i18n);
  app.use(vfm);
  app.use(store); // Utilisez le store Vuex
  app.mount('#app');
});