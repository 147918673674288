<template>
  <div class="flex justify-center">
    <div
      class="border border-gray-300 p-6 bg-white shadow-lg rounded-md dark:bg-gray-800"
    >
      <h1 class="text-4xl font-extrabold mb-6 text-center dark:text-white">
        Page d'administration
      </h1>

      <div class="tabs dark:text-white">
        <button
          class="tab"
          :class="{ 'active-tab': activeSection === 'userCreation' }"
          @click="setActiveSection('userCreation')"
        >
          Créer un utilisateur
        </button>
        <button
          class="tab"
          :class="{ 'active-tab': activeSection === 'calendarCreation' }"
          @click="setActiveSection('calendarCreation')"
        >
          Créer un calendrier
        </button>
        <button
          class="tab"
          :class="{ 'active-tab': activeSection === 'calendarManagement' }"
          @click="setActiveSection('calendarManagement')"
        >
          Gestion des calendiers
        </button>
        <button
          class="tab"
          :class="{ 'active-tab': activeSection === 'userRightsManagement' }"
          @click="setActiveSection('userRightsManagement')"
        >
          Gestions des droits
        </button>
      </div>

      <!-- Création d'utilisateur -->
      <div v-if="activeSection === 'userCreation'" class="mb-6">
        <h2 class="text-2xl dark:text-white font-bold mb-2 text-center">
          Création d'utilisateur
        </h2>
        <div
          class="p-4 border border-gray-200 rounded mx-auto w-full max-w-[550px] text-left"
        >
          <form @submit.prevent="createUser">
            <div class="mb-4">
              <label class="block text-gray-70 dark:text-white">Prénom :</label>
              <input
                v-model="newUser.firstName"
                type="text"
                required
                class="mt-1 block w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-white">Nom :</label>
              <input
                v-model="newUser.lastName"
                type="text"
                required
                class="mt-1 block w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-white">Email :</label>
              <input
                v-model="newUser.email"
                type="email"
                required
                class="mt-1 block w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-white"
                >Mot de passe :</label
              >
              <input
                v-model="newUser.password"
                type="password"
                required
                class="mt-1 block w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-white">Rôles :</label>
              <div class="flex flex-wrap">
                <span
                  v-for="role in combinedRoles"
                  :key="role.calendarId"
                  class="cursor-pointer m-1 px-3 py-1 rounded-full flex items-center bg-gray-200 text-gray-700"
                >
                  {{ role.name }}
                  <span class="ml-2 flex items-center">
                    <svg
                      @click="toggleRole(`lecture_${role.calendarId}`)"
                      :class="{
                        'text-teal-600 fill-current': roleHas(
                          `lecture_${role.calendarId}`
                        ),
                        'text-gray-400 fill-current': !roleHas(
                          `lecture_${role.calendarId}`
                        ),
                      }"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer ml-1 h-7 w-7"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M20 16.78c.002-1.8.003-2.812 0-4.027-.001-.417.284-.638.567-.638.246 0 .49.168.538.52.19 1.412.411 2.816.547 3.146.042.099.113.141.185.141.123 0 .244-.123.206-.284-.255-1.069-.493-2.519-.607-3.334 1.904 1.854 2.314 2.005 2.192 3.548-.089 1.129-.52 2.508.373 4.255l-2.563.893c-.062-.314-.138-.637-.226-.933-.515-1.721-1.214-1.752-1.212-3.287zm-16.567-4.665c-.246 0-.49.168-.538.52-.19 1.412-.411 2.816-.547 3.146-.042.099-.113.141-.185.141-.123 0-.244-.123-.206-.284.255-1.069.493-2.519.607-3.334-1.904 1.854-2.314 2.005-2.192 3.548.09 1.128.521 2.507-.372 4.254l2.562.894c.062-.314.138-.637.226-.933.515-1.721 1.214-1.752 1.212-3.287-.002-1.8-.003-2.812 0-4.027.001-.418-.285-.638-.567-.638zm1.567.642zm14.001 2.637c-2.354.194-4.35.62-6.001 1.245v-9.876l.057-.036c1.311-.816 3.343-1.361 5.943-1.603v7.633c-.002-.459.165-.881.469-1.186.377-.378.947-.562 1.531-.391v-8.18c-3.438.105-6.796.658-9 2.03-2.204-1.372-5.562-1.925-9-2.03v8.18c.583-.17 1.153.012 1.531.391.304.305.471.726.469 1.184v-7.631c2.6.242 4.632.788 5.943 1.604l.057.035v9.876c-1.651-.626-3.645-1.052-6-1.246v1.385c0 .234-.021.431-.046.622 2.249.193 4.372.615 6.046 1.381.638.292 1.362.291 2 0 1.675-.766 3.798-1.188 6.046-1.381-.025-.191-.046-.386-.046-.621l.001-1.385zm-12.001-2.426c1.088.299 2.122.64 3 .968v1.064c-.823-.345-1.879-.705-3-1.015v-1.017zm0-1.014c1.121.31 2.177.67 3 1.015v-1.064c-.878-.328-1.912-.669-3-.968v1.017zm0-5.09v1.017c1.121.311 2.177.67 3 1.015v-1.064c-.878-.328-1.912-.669-3-.968zm0 3.058c1.121.31 2.177.67 3 1.015v-1.063c-.878-.328-1.912-.669-3-.968v1.016zm10 4.063c-1.121.31-2.177.67-3 1.015v-1.064c.878-.328 1.912-.669 3-.968v1.017zm0-3.048c-1.088.299-2.122.64-3 .968v1.064c.823-.345 1.879-.705 3-1.015v-1.017zm-3-3.105v1.064c.823-.345 1.879-.705 3-1.015v-1.017c-1.088.299-2.122.64-3 .968zm3 1.074c-1.088.299-2.122.64-3 .968v1.064c.823-.345 1.879-.705 3-1.015v-1.017z"
                      />
                    </svg>
                    <svg
                      @click="toggleRole(`ecriture_${role.calendarId}`)"
                      :class="{
                        'text-teal-600 fill-current': roleHas(
                          `ecriture_${role.calendarId}`
                        ),
                        'text-gray-400 fill-current': !roleHas(
                          `ecriture_${role.calendarId}`
                        ),
                      }"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer ml-1 h-6 w-6"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M7.127 22.562l-7.127 1.438 1.438-7.128 5.689 5.69zm1.414-1.414l11.228-11.225-5.69-5.692-11.227 11.227 5.689 5.69zm9.768-21.148l-2.816 2.817 5.691 5.691 2.816-2.819-5.691-5.689z"
                      />
                    </svg>
                  </span>
                </span>
                <span
                  @click="toggleRole('admin')"
                  :class="
                    roleHas('admin')
                      ? 'bg-teal-600 text-white'
                      : 'bg-gray-200 text-gray-700'
                  "
                  class="cursor-pointer m-1 px-3 py-1 rounded-full flex items-center"
                >
                  Admin
                </span>
              </div>
            </div>
            <button
              type="submit"
              class="w-full font-medium text-white px-4 py-2 rounded-md bg-teal-600 hover:bg-teal-700 focus:ring-2 focus:outline-none focus:ring-teal-500"
            >
              Créer utilisateur
            </button>
          </form>
        </div>
      </div>

      <!-- Création de calendrier -->
      <div v-if="activeSection === 'calendarCreation'" class="mb-6">
        <h2 class="text-2xl dark:text-white font-bold mb-2 text-center">
          Création de calendrier
        </h2>
        <div
          class="p-4 border border-gray-200 rounded mx-auto w-full max-w-[550px] text-left"
        >
          <form @submit.prevent="createCalendar">
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-white"
                >ID du calendrier :</label
              >
              <input
                v-model="newCalendar.id"
                type="text"
                required
                class="mt-1 block w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-white"
                >Nom du calendrier :</label
              >
              <input
                v-model="newCalendar.name"
                type="text"
                required
                class="mt-1 block w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-white"
                >Mois de début :</label
              >
              <select
                v-model="newCalendar.startMonth"
                required
                class="mt-1 block w-full p-2 border border-gray-300 rounded"
              >
                <option v-for="month in months" :key="month" :value="month">
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-white"
                >Mois de fin :</label
              >
              <select
                v-model="newCalendar.endMonth"
                required
                class="mt-1 block w-full p-2 border border-gray-300 rounded"
              >
                <option v-for="month in months" :key="month" :value="month">
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-white"
                >Nombre maximum d'inscrits :</label
              >
              <input
                v-model.number="newCalendar.maxInscrits"
                type="number"
                required
                min="1"
                class="mt-1 block w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <button
              type="submit"
              class="w-full font-medium text-white px-4 py-2 rounded-md bg-teal-600 hover:bg-teal-700 focus:ring-2 focus:outline-none focus:ring-teal-500"
            >
              Créer calendrier
            </button>
          </form>
        </div>
      </div>

      <!-- Gestion des calendriers -->
      <div v-if="activeSection === 'calendarManagement'" class="mb-6">
        <h2 class="text-2xl dark:text-white font-bold mb-2 text-center">
          Gestion des calendriers
        </h2>
        <div
          class="p-4 border border-gray-200 rounded mx-auto w-full"
        >
          <table
            class="min-w-full divide-y divide-gray-200 dark:divide-gray-700 table-auto"
          >
            <thead class="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th
                  class="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                >
                  ID
                </th>
                <th
                  class="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                >
                  Nom
                </th>
                <th
                  class="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                >
                  Mois de début
                </th>
                <th
                  class="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                >
                  Mois de fin
                </th>
                <th
                  class="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                >
                  Max Inscrits
                </th>
                <th
                  class="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody
              class="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700"
            >
              <tr v-for="calendar in calendars" :key="calendar.id">
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white"
                >
                  {{ calendar.id }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white"
                >
                  {{ calendar.name }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                >
                  <select
                    v-model="calendar.startMonth"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded"
                  >
                    <option v-for="month in months" :key="month" :value="month">
                      {{ month }}
                    </option>
                  </select>
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                >
                  <select
                    v-model="calendar.endMonth"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded"
                  >
                    <option v-for="month in months" :key="month" :value="month">
                      {{ month }}
                    </option>
                  </select>
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                >
                  <input
                    v-model.number="calendar.maxInscrits"
                    type="number"
                    min="1"
                    class="mt-1 block w-full p-2 border border-gray-300 rounded"
                  />
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                >
                  <button
                    @click="updateCalendar(calendar)"
                    class="font-medium text-white px-4 py-2 rounded bg-teal-600 hover:bg-teal-700 focus:ring-2 focus:outline-none focus:ring-teal-500"
                  >
                    Sauvegarder
                  </button>
                  <button
                    @click="deleteCalendar(calendar.id)"
                    class="font-medium text-white px-4 py-2 rounded bg-rose-600 hover:bg-rose-700 focus:ring-2 focus:outline-none focus:ring-rose-500"
                  >
                    Supprimer
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Gestion des droits des utilisateurs -->
      <div v-if="activeSection === 'userRightsManagement'" class="mb-6">
        <h2 class="text-2xl dark:text-white font-bold mb-2 text-center">
          Gestion des droits des utilisateurs
        </h2>
        <div
          class="p-4 border border-gray-200 rounded mx-auto w-full max-w-[1000px]"
        >
          <table
            class="min-w-full divide-y divide-gray-200 dark:divide-gray-700"
          >
            <thead class="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                >
                  Utilisateur
                </th>
                <th
                  class="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                >
                  Rôles
                </th>
              </tr>
            </thead>
            <tbody
              class="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700"
            >
              <tr v-for="user in users" :key="user.id">
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100"
                >
                  {{ user.firstName }} {{ user.lastName }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                >
                  <div class="flex flex-wrap">
                    <span
                      v-for="role in combinedRoles"
                      :key="role.calendarId"
                      class="cursor-pointer m-1 px-3 py-1 rounded-full flex items-center bg-gray-200 text-gray-700"
                    >
                      {{ role.name }}
                      <span class="ml-2 flex items-center">
                        <svg
                          @click="
                            toggleUserRole(user, `lecture_${role.calendarId}`)
                          "
                          :class="{
                            'text-teal-600 fill-current': roleHasRead(
                              user,
                              role.calendarId
                            ),
                            'text-gray-400 fill-current': !roleHasRead(
                              user,
                              role.calendarId
                            ),
                          }"
                          xmlns="http://www.w3.org/2000/svg"
                          class="cursor-pointer ml-1 h-7 w-7"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M20 16.78c.002-1.8.003-2.812 0-4.027-.001-.417.284-.638.567-.638.246 0 .49.168.538.52.19 1.412.411 2.816.547 3.146.042.099.113.141.185.141.123 0 .244-.123.206-.284-.255-1.069-.493-2.519-.607-3.334 1.904 1.854 2.314 2.005 2.192 3.548-.089 1.129-.52 2.508.373 4.255l-2.563.893c-.062-.314-.138-.637-.226-.933-.515-1.721-1.214-1.752-1.212-3.287zm-16.567-4.665c-.246 0-.49.168-.538.52-.19 1.412-.411 2.816-.547 3.146-.042.099-.113.141-.185.141-.123 0-.244-.123-.206-.284.255-1.069.493-2.519.607-3.334-1.904 1.854-2.314 2.005-2.192 3.548.09 1.128.521 2.507-.372 4.254l2.562.894c.062-.314.138-.637.226-.933.515-1.721 1.214-1.752 1.212-3.287-.002-1.8-.003-2.812 0-4.027.001-.418-.285-.638-.567-.638zm1.567.642zm14.001 2.637c-2.354.194-4.35.62-6.001 1.245v-9.876l.057-.036c1.311-.816 3.343-1.361 5.943-1.603v7.633c-.002-.459.165-.881.469-1.186.377-.378.947-.562 1.531-.391v-8.18c-3.438.105-6.796.658-9 2.03-2.204-1.372-5.562-1.925-9-2.03v8.18c.583-.17 1.153.012 1.531.391.304.305.471.726.469 1.184v-7.631c2.6.242 4.632.788 5.943 1.604l.057.035v9.876c-1.651-.626-3.645-1.052-6-1.246v1.385c0 .234-.021.431-.046.622 2.249.193 4.372.615 6.046 1.381.638.292 1.362.291 2 0 1.675-.766 3.798-1.188 6.046-1.381-.025-.191-.046-.386-.046-.621l.001-1.385zm-12.001-2.426c1.088.299 2.122.64 3 .968v1.064c-.823-.345-1.879-.705-3-1.015v-1.017zm0-1.014c1.121.31 2.177.67 3 1.015v-1.064c-.878-.328-1.912-.669-3-.968v1.017zm0-5.09v1.017c1.121.311 2.177.67 3 1.015v-1.064c-.878-.328-1.912-.669-3-.968zm0 3.058c1.121.31 2.177.67 3 1.015v-1.063c-.878-.328-1.912-.669-3-.968v1.016zm10 4.063c-1.121.31-2.177.67-3 1.015v-1.064c.878-.328 1.912-.669 3-.968v1.017zm0-3.048c-1.088.299-2.122.64-3 .968v1.064c.823-.345 1.879-.705 3-1.015v-1.017zm-3-3.105v1.064c.823-.345 1.879-.705 3-1.015v-1.017c-1.088.299-2.122.64-3 .968zm3 1.074c-1.088.299-2.122.64-3 .968v1.064c.823-.345 1.879-.705 3-1.015v-1.017z"
                          />
                        </svg>
                        <svg
                          @click="
                            toggleUserRole(user, `ecriture_${role.calendarId}`)
                          "
                          :class="{
                            'text-teal-600 fill-current': roleHasWrite(
                              user,
                              role.calendarId
                            ),
                            'text-gray-400 fill-current': !roleHasWrite(
                              user,
                              role.calendarId
                            ),
                          }"
                          xmlns="http://www.w3.org/2000/svg"
                          class="cursor-pointer ml-1 h-6 w-6"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M7.127 22.562l-7.127 1.438 1.438-7.128 5.689 5.69zm1.414-1.414l11.228-11.225-5.69-5.692-11.227 11.227 5.689 5.69zm9.768-21.148l-2.816 2.817 5.691 5.691 2.816-2.819-5.691-5.689z"
                          />
                        </svg>
                      </span>
                    </span>
                    <span
                      @click="toggleUserRole(user, 'admin')"
                      :class="
                        roleHasUser(user, 'admin')
                          ? 'bg-teal-600 text-white'
                          : 'bg-gray-200 text-gray-700'
                      "
                      class="cursor-pointer m-1 px-3 py-1 rounded-full flex items-center"
                    >
                      Admin
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { auth, db } from "../firebaseConfig";
import { addNewCalendarRoute } from "../router";

export default {
  data() {
    return {
      newUser: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        roles: [],
      },
      newCalendar: {
        id: "",
        name: "",
        startMonth: "Janvier",
        endMonth: "Décembre",
        maxInscrits: 10, // Ajouter une valeur par défaut ici
      },
      users: [],
      calendars: [],
      months: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      adminEmail: "", // Stocker l'email de l'admin
      adminPassword: "", // Stocker le mot de passe de l'admin
      availableRoles: ["admin"],
      combinedRoles: [],
      activeSection: "userCreation", // Ajouter une variable pour la section active
    };
  },
  async created() {
    await this.fetchUsers();
    await this.fetchCalendars();
  },
  methods: {
    setActiveSection(section) {
      this.activeSection = section;
    },
    async createUser() {
      try {
        // Sauvegarder les informations d'authentification de l'admin
        this.adminEmail = auth.currentUser.email;
        this.adminPassword = prompt(
          "Veuillez entrer votre mot de passe pour confirmer l'action:"
        );

        // Créer le nouvel utilisateur
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          this.newUser.email,
          this.newUser.password
        );
        const user = userCredential.user;

        // Déconnexion de l'utilisateur créé
        await signOut(auth);

        // Reconnexion avec le compte administrateur
        await signInWithEmailAndPassword(
          auth,
          this.adminEmail,
          this.adminPassword
        );

        // Ajout des informations utilisateur dans Firestore
        await setDoc(doc(db, "users", user.uid), {
          firstName: this.newUser.firstName,
          lastName: this.newUser.lastName,
          email: this.newUser.email,
          roles: this.newUser.roles,
        });

        alert("Utilisateur créé avec succès");
        this.newUser.firstName = "";
        this.newUser.lastName = "";
        this.newUser.email = "";
        this.newUser.password = "";
        this.newUser.roles = [];
        await this.fetchUsers();
      } catch (error) {
        console.error("Erreur lors de la création de l'utilisateur :", error);
        alert("Erreur lors de la création de l'utilisateur");
      }
    },
    async createCalendar() {
      try {
        // Vérifier si un calendrier avec cet ID existe déjà
        const calendarDoc = await getDoc(
          doc(db, "calendars", this.newCalendar.id)
        );
        if (calendarDoc.exists()) {
          alert("Un calendrier avec cet ID existe déjà.");
          return;
        }

        // Créer le calendrier s'il n'existe pas
        await setDoc(doc(db, "calendars", this.newCalendar.id), {
          name: this.newCalendar.name,
          startMonth: this.newCalendar.startMonth,
          endMonth: this.newCalendar.endMonth,
          maxInscrits: this.newCalendar.maxInscrits, // Ajouter maxInscrits ici
        });
        alert("Calendrier créé avec succès");

        // Ajout de la nouvelle route dynamique
        await addNewCalendarRoute(this.newCalendar);

        // Mise à jour des calendriers pour le menu
        this.$emit("calendar-updated");

        this.newCalendar.id = "";
        this.newCalendar.name = "";
        this.newCalendar.startMonth = "Janvier";
        this.newCalendar.endMonth = "Décembre";
        this.newCalendar.maxInscrits = 10; // Réinitialiser maxInscrits
        await this.fetchCalendars();
      } catch (error) {
        console.error("Erreur lors de la création du calendrier :", error);
        alert("Erreur lors de la création du calendrier");
      }
    },
    async fetchUsers() {
      const querySnapshot = await getDocs(collection(db, "users"));
      this.users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },
    async fetchCalendars() {
      const querySnapshot = await getDocs(collection(db, "calendars"));
      this.calendars = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const calendarIds = querySnapshot.docs.map((doc) => doc.id);

      this.availableRoles = ["admin"];
      this.combinedRoles = calendarIds.map((id) => ({
        calendarId: id,
        name: id,
      }));
    },
    async updateCalendar(calendar) {
      try {
        await updateDoc(doc(db, "calendars", calendar.id), {
          startMonth: calendar.startMonth,
          endMonth: calendar.endMonth,
          maxInscrits: calendar.maxInscrits, // Ajouter maxInscrits ici
        });
        alert("Calendrier mis à jour avec succès");
        await this.fetchCalendars();
      } catch (error) {
        console.error("Erreur lors de la mise à jour du calendrier :", error);
        alert("Erreur lors de la mise à jour du calendrier");
      }
    },
    async deleteCalendar(calendarId) {
      try {
        await deleteDoc(doc(db, "calendars", calendarId));
        alert("Calendrier supprimé avec succès");
        await this.fetchCalendars();
      } catch (error) {
        console.error("Erreur lors de la suppression du calendrier :", error);
        alert("Erreur lors de la suppression du calendrier");
      }
    },
    toggleRole(role) {
      const index = this.newUser.roles.indexOf(role);
      if (index > -1) {
        this.newUser.roles.splice(index, 1);
      } else {
        this.newUser.roles.push(role);
      }
    },
    toggleUserRole(user, role) {
      const index = user.roles.indexOf(role);
      if (index > -1) {
        user.roles.splice(index, 1);
      } else {
        user.roles.push(role);
      }
      updateDoc(doc(db, "users", user.id), { roles: user.roles })
        .then(() => {
          this.fetchUsers();
        })
        .catch((error) => {
          console.error("Erreur lors de la mise à jour des rôles :", error);
          alert("Erreur lors de la mise à jour des rôles");
        });
    },
    roleHas(role) {
      return this.newUser.roles.includes(role);
    },
    roleHasRead(user, calendarId) {
      return user.roles && user.roles.includes(`lecture_${calendarId}`);
    },
    roleHasWrite(user, calendarId) {
      return user.roles && user.roles.includes(`ecriture_${calendarId}`);
    },
    roleHasUser(user, role) {
      return user.roles && user.roles.includes(role);
    },
  },
};
</script>
