<template>
  <div id="app" :class="darkMode ? 'dark' : ''" ref="appContainer">
    <div class="h-screen dark:bg-gray-800 bg-stone-50 flex flex-col">
      <!-- début navigation -->
      <nav v-if="isAuthenticated" class="dark:bg-gray-600 bg-stone-50 shadow-md mb-2.5">
        <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div class="relative flex h-16 items-center justify-between">
            <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <!-- Mobile menu button-->
              <button
                @click="toggleMobileMenu"
                type="button"
                class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                :aria-expanded="isMobileMenuOpen"
              >
                <span class="sr-only">Open main menu</span>
                <svg
                  v-if="!isMobileMenuOpen"
                  class="block h-6 w-6 pointer-events-none"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
                <svg
                  v-else
                  class="block h-6 w-6 pointer-events-none"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div class="flex flex-shrink-0 items-center">
                <div class="h-8 w-auto">
                  <!-- SVG Logo UAC -->
                  <svg
                    class="dark:text-slate-50 dark:fill-current"
                    width="100%"
                    height="100%"
                    viewBox="0 0 600 600"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    xmlns:serif="http://www.serif.com/"
                    style="
                      fill-rule: evenodd;
                      clip-rule: evenodd;
                      stroke-linejoin: round;
                      stroke-miterlimit: 2;
                    "
                  >
                    <g transform="matrix(3.12142,0,0,3.12142,-488.156,-747.619)">
                      <rect x="216.668" y="243.197" width="71.663" height="26.837" />
                    </g>
                    <g transform="matrix(3.12142,0,0,3.12142,296.373,289.503)">
                      <path
                        d="M0,52.684L5.911,49.308L5.911,95.776L36.408,95.742L36.299,-13.279C36.299,-38.041 27.297,-43.105 1.125,-43.105C-25.888,-43.105 -34.334,-37.756 -34.334,-13.279L-34.334,14.866L-3.938,14.866L-3.938,-13.279C-3.938,-17.495 -1.973,-19.468 1.125,-19.468C4.223,-19.468 5.911,-16.932 5.911,-13.279L5.911,20.492L-20.824,35.968C-30.111,41.316 -34.334,48.352 -34.334,61.015L-34.334,95.789L-3.938,95.748L-3.938,61.123C-3.938,56.907 -2.251,54.094 0,52.684"
                        style="fill-rule: nonzero"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="hidden sm:ml-6 sm:block">
                <div class="flex space-x-4 items-center">
                  <!-- Affichage du menu des calendriers -->
                  <div class="relative group">
                    <button class="rounded-md px-3 py-2 text-sm font-bold text-center text-grey-800 hover:text-pink-300 uppercase font-montserrat dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white">
                      Calendriers
                    </button>
                    <div class="absolute hidden group-hover:block bg-white dark:bg-gray-800 shadow-lg rounded-md w-48">
                      <template v-for="calendar in filteredCalendars">
                        <router-link
                          v-if="isAuthenticated"
                          :key="calendar.id"
                          :to="`/calendar/${calendar.id}`"
                          @click="closeMobileMenu"
                          class="block rounded-md px-3 py-2 text-sm font-bold text-center text-grey-800 hover:text-pink-300 uppercase font-montserrat dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          {{ calendar.name }}
                        </router-link>
                      </template>
                    </div>
                  </div>

                  <router-link
                    v-if="isAuthenticated && !isAdmin"
                    to="/mon-planning"
                    @click="closeMobileMenu"
                    class="rounded-md px-3 py-2 text-sm font-bold text-center text-grey-800 hover:text-pink-300 uppercase font-montserrat dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >Mon Planning</router-link
                  >

                  <router-link
                    v-if="isAuthenticated && hasWriteAccess"
                    to="/temps"
                    @click="closeMobileMenu"
                    class="rounded-md px-3 py-2 text-sm font-bold text-center text-grey-800 hover:text-pink-300 uppercase font-montserrat dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >Temps</router-link
                  >
                  <router-link
                    v-if="isAuthenticated && isAdmin"
                    to="/admin"
                    @click="closeMobileMenu"
                    class="rounded-md px-3 py-2 text-sm font-bold text-center text-grey-800 hover:text-pink-300 uppercase font-montserrat dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >Admin</router-link
                  >
                </div>
              </div>
            </div>
            <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <!-- Dark / Light Mode Switch -->
              <button @click="toggleDarkMode" class="relative rounded-md px-3 py-2 focus:outline-none">
                <span v-if="!darkMode">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5 transform -rotate-90 fill-current text-gray-700 hover:text-amber-300"
                  >
                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                  </svg>
                </span>
                <span v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5 fill-current dark:text-white dark:hover:text-amber-300"
                  >
                    <path
                      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </button>

              <!-- Se déconnecter -->
              <button
                v-if="isAuthenticated"
                @click="logout"
                type="button"
                class="relative px-3 py-2 p-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 dark:text-white dark:hover:text-pink-300 text-gray-700 hover:text-pink-300"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
              </button>

              <!-- Profile dropdown -->
              <div class="relative ml-3">
                <div>
                  <router-link
                    v-if="isAuthenticated"
                    to="/profil"
                    class="relative flex rounded-full border-2 border-gray-700 focus:border-0 hover:border-pink-200 hover:border-4 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-pink-300"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img
                      v-if="userProfileImage"
                      class="h-8 w-8 rounded-full"
                      :src="userProfileImage"
                      alt="Profile"
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile menu, show/hide based on menu state. -->
        <div class="sm:hidden" id="mobile-menu" v-show="isMobileMenuOpen">
          <div class="space-y-1 px-2 pb-3 pt-2">
            <div class="relative group">
              <button class="block w-full text-left rounded-md px-3 py-2 text-base font-bold text-grey-800 hover:text-pink-300 uppercase font-montserrat dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white">
                Calendriers
              </button>
              <div class="absolute hidden group-hover:block bg-white dark:bg-gray-800 shadow-lg rounded-md w-full">
                <template v-for="calendar in filteredCalendars">
                  <router-link
                    v-if="isAuthenticated"
                    :key="calendar.id"
                    :to="`/calendar/${calendar.id}`"
                    @click="closeMobileMenu"
                    class="block rounded-md px-3 py-2 text-sm font-bold text-center text-grey-800 hover:text-pink-300 uppercase font-montserrat dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    {{ calendar.name }}
                  </router-link>
                </template>
              </div>
            </div>

            <router-link
              v-if="isAuthenticated && !isAdmin"
              to="/mon-planning"
              @click="closeMobileMenu"
              class="block rounded-md px-3 py-2 text-base font-bold text-grey-800 hover:text-pink-300 uppercase font-montserrat dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
              >Mon Planning</router-link
            >

            <router-link
              v-if="isAuthenticated && hasWriteAccess"
              to="/temps"
              @click="closeMobileMenu"
              class="block rounded-md px-3 py-2 text-base font-bold text-grey-800 hover:text-pink-300 uppercase font-montserrat dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
              >Temps</router-link
            >
            <router-link
              v-if="isAuthenticated && isAdmin"
              to="/admin"
              @click="closeMobileMenu"
              class="block rounded-md px-3 py-2 text-base font-bold text-grey-800 hover:text-pink-300 uppercase font-montserrat dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
              >Admin</router-link
            >
          </div>
        </div>
      </nav>
      <router-view :class="containerClass"></router-view>
      <footer v-if="isAuthenticated" class="sm:px-0 sm:py-0 lg:px-0 bg-stone-50 dark:bg-gray-800 shadow-mdInv">
        <div class="w-full p-4 md:flex md:justify-between dark:bg-gray-600 bg-stone-50 sm:px-0 lg:px-16">
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://www.usineachapeaux.fr//" target="_blank" class="hover:underline">Loona & Isolia</a>.</span>
        </div>
      </footer>
      <ModalsContainer />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed, onBeforeUnmount } from "vue";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import { ModalsContainer } from "vue-final-modal";
import { fetchUserRoles, fetchUserProfileImage, getCalendarNames, hasWriteAccessToAnyCalendar } from "./services/servicesPlanning.js";

export default {
  components: {
    ModalsContainer,
  },
  setup() {
    const isAuthenticated = ref(false);
    const isAdmin = ref(false);
    const userRoles = ref([]);
    const userProfileImage = ref("");
    const hasWriteAccess = ref(false);
    const auth = getAuth();
    const router = useRouter();
    const calendars = ref([]);
    const isMobileMenuOpen = ref(false);
    const appContainer = ref(null);

    // Vérifiez les préférences système de l'utilisateur pour le mode sombre
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const storedTheme = localStorage.getItem("theme");
    const darkMode = ref(storedTheme ? storedTheme === "dark" : prefersDarkScheme);

    const toggleDarkMode = () => {
      darkMode.value = !darkMode.value;
      localStorage.setItem("theme", darkMode.value ? "dark" : "light");
      if (darkMode.value) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    };

    const toggleMobileMenu = () => {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    };

    const closeMobileMenu = () => {
      isMobileMenuOpen.value = false;
    };

    const handleClickOutside = (event) => {
      if (appContainer.value && !appContainer.value.contains(event.target) && isMobileMenuOpen.value) {
        closeMobileMenu();
      }
    };

    // const checkSessionExpiration = () => {
    //   const loginTime = localStorage.getItem("loginTime");
    //   const sessionDuration = 30 * 60 * 1000; // 30 minutes en millisecondes
    //   if (loginTime) {
    //     const currentTime = Date.now();
    //     if (currentTime - loginTime > sessionDuration) {
    //       auth.signOut().then(() => {
    //         localStorage.removeItem("loginTime");
    //         router.push("/login");
    //       });
    //     }
    //   }
    // };

    const hasAccess = (calendarId) => {
      const accessRight = `lecture_${calendarId}`;
      const access = userRoles.value.includes("admin") || userRoles.value.includes(accessRight);
      return access;
    };

    const logout = async () => {
      const auth = getAuth();
      await signOut(auth);
      router.push("/login");
    };

    const fetchCalendars = async () => {
      if (isAuthenticated.value) {
        calendars.value = await getCalendarNames();
      }
    };

    const filteredCalendars = computed(() => {
      return calendars.value.filter((calendar) => hasAccess(calendar.id));
    });

    const containerClass = computed(() => {
      if (router.currentRoute.value.name === 'LoginPage') {
        return 'flex-1 containerCalendar bg-stone-50 dark:bg-gray-800';
      } else {
        return 'flex-1 containerCalendar sm:px-0 sm:py-3 lg:px-8 bg-stone-50 dark:bg-gray-800';
      }
    });

    onMounted(() => {
      document.title = "Planning - Usine à chapeaux";
      onAuthStateChanged(auth, async (user) => {
        isAuthenticated.value = !!user;
        if (isAuthenticated.value) {
          //checkSessionExpiration();
          if (user) {
            userRoles.value = await fetchUserRoles(user.uid);
            userProfileImage.value = await fetchUserProfileImage(user.uid);
            hasWriteAccess.value = await hasWriteAccessToAnyCalendar();
            isAdmin.value = userRoles.value.includes("admin");
            fetchCalendars();
          }
        } else {
          router.push("/login");
        }
      });

      if (darkMode.value) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }

      document.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    watch(isAuthenticated, (newVal) => {
      if (!newVal) {
        userProfileImage.value = "";
      }
    });

    return {
      isAuthenticated,
      isAdmin,
      hasAccess,
      logout,
      userProfileImage,
      filteredCalendars,
      hasWriteAccess,
      darkMode,
      toggleDarkMode,
      isMobileMenuOpen,
      toggleMobileMenu,
      closeMobileMenu,
      appContainer,
      containerClass
    };
  },
};
</script>
