<template>
  <!-- ProfilPage -->
  <div class="flex justify-center">
    <div>
      <div class="border border-gray-300 p-6 bg-white shadow-lg rounded-md max-w-2xl dark:bg-gray-800">
        <div>
          <h2 class="mb-4 text-4xl text-center font-extrabold dark:text-white pb-1">
            Profil de l'utilisateur
          </h2>
          <div v-if="profileImageUrl" class="flex justify-center items-center">
            <img :src="profileImageUrl" alt="Profile Image" class="w-32 h-32 object-cover rounded-full"/>
          </div>

          <form @submit.prevent="updateProfile">

            <label class="text-gray-600 dark:text-gray-400 text-left">
              Prénom :
              <input class="w-full mb-4 py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100" v-model="firstName" type="text" required />
            </label>

            <label class="text-gray-600 dark:text-gray-400 text-left my-1.5">
              Nom :
              <input class="w-full mb-4 py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100" v-model="lastName" type="text" required />
            </label>
            <br />

            <p class="text-gray-600 dark:text-gray-400 text-left my-1">Email : </p>
            <p class="w-full mb-4 py-3 bg-gray-200 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-900 dark:text-gray-100">
              {{ userEmail }} <span class="italic text-xs"> (Ne peux être modifier)</span> </p>

            
            <label class="block text-gray-600 dark:text-gray-400 text-left" for="file_input">Changer image de profil :</label>
            <input type="file" @change="uploadImage" 
            class="w-full mb-4 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help">
            <!-- <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG or GIF (MAX. 800x400px).</p> -->

            <button
              type="submit"
              class="w-full my-1.5 text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Mettre à jour le profil
            </button>

          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { storage, auth, db } from "../firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";

export default {
  data() {
    return {
      user: null,
      userEmail: "",
      firstName: "",
      lastName: "",
      profileImageUrl: null,
    };
  },
  methods: {
    async uploadImage(event) {
      const file = event.target.files[0];
      if (!file) return;

      const user = auth.currentUser;
      if (!user) {
        alert("Veuillez vous connecter d'abord.");
        return;
      }

      const storageReference = storageRef(storage, `profileImages/${user.uid}`);
      try {
        await uploadBytes(storageReference, file);
        const url = await getDownloadURL(storageReference);
        this.profileImageUrl = url;
        //console.log("File uploaded successfully. File available at", url);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
    async fetchUserProfile() {
      const user = auth.currentUser;
      if (!user) {
        alert("Veuillez vous connecter d'abord.");
        return;
      }

      this.userEmail = user.email;

      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        this.firstName = data.firstName || "";
        this.lastName = data.lastName || "";
        this.profileImageUrl = await this.fetchProfileImageUrl(user.uid);
      } else {
        //console.log("No such document!");
      }
    },
    async fetchProfileImageUrl(userId) {
      try {
        const storageReference = storageRef(storage, `profileImages/${userId}`);
        return await getDownloadURL(storageReference);
      } catch (error) {
        console.error("Error fetching profile image:", error);
        return null;
      }
    },
    async updateProfile() {
      const user = auth.currentUser;
      if (!user) {
        alert("Veuillez vous connecter d'abord.");
        return;
      }

      try {
        const docRef = doc(db, "users", user.uid);
        await setDoc(
          docRef,
          {
            firstName: this.firstName,
            lastName: this.lastName,
          },
          { merge: true }
        ); // Utiliser merge: true pour fusionner les champs
        alert("Profile updated successfully!");
      } catch (error) {
        console.error("Error updating profile:", error);
        alert("Error updating profile. See console for details.");
      }
    },
  },
  async mounted() {
    this.user = auth.currentUser;
    if (this.user) {
      await this.fetchUserProfile();
    }
  },
};
</script>
