import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginPage from "./components/LoginPage.vue";
import Profil from "./components/ProfilPage.vue";
import CalendarComponent from "./components/CalendarComponent.vue";
import TempsPage from "./components/TempsPage.vue";
import AdminPage from "./components/AdminPage.vue";
import MonPlanning from './components/MonPlanning.vue';
import NoAccess from "./components/NoAccess.vue";
import { redirectToFirstAccessibleCalendar, getCalendarNames, hasWriteAccessToAnyCalendar, checkAdminRole } from "./services/servicesPlanning.js";

let router;

const staticRoutes = [
  { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/profil", name: "ProfilPage", component: Profil, meta: { requiresAuth: true } },
  { path: "/", redirect: "/login" },
  { path: "/temps", name: "TempsPage", component: TempsPage, meta: { requiresAuth: true, requiresWriteAccess: true } },
  { path: "/admin", name: "AdminPage", component: AdminPage, meta: { requiresAuth: true, requiresAdmin: true } },
  { path: "/mon-planning", name: "MonPlanningPage", component: MonPlanning, meta: { requiresAuth: true } },
  { path: "/no-access", name: "NoAccessPage", component: NoAccess },
];

export const createDynamicRoutes = () => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    onAuthStateChanged(auth, async () => {
      const calendarNames = await getCalendarNames();
        const dynamicRoutes = calendarNames.map(calendar => ({
          path: `/calendar/${calendar.id}`,
          name: `Calendar${calendar.id}`,
          component: CalendarComponent,
          props: { calendarId: calendar.id, calendarName: calendar.name },
          meta: { requiresAuth: true },
        }));
        resolve(dynamicRoutes);
    }, reject);
  });
};

export const createRouterWithDynamicRoutes = async () => {
  const dynamicRoutes = await createDynamicRoutes();
  const routes = [...staticRoutes, ...dynamicRoutes];

  router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresWriteAccess = to.matched.some(record => record.meta.requiresWriteAccess);
    const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
    const auth = getAuth();
    const user = auth.currentUser;
    let nextCalled = false;

    const safeNext = (...args) => {
      if (!nextCalled) {
        nextCalled = true;
        next(...args);
      }
    };

    const handleAuthState = async (user) => {
      if (!user && requiresAuth) {
        safeNext("/login");
        return;
      }
      if (user) {
        if (to.path === "/login") {
          await redirectToFirstAccessibleCalendar(user.uid, safeNext);
        } else if (requiresWriteAccess) {
          const hasAccess = await hasWriteAccessToAnyCalendar();
          if (hasAccess) {
            safeNext();
          } else {
            safeNext("/no-access");
          }
        } else if (requiresAdmin) {
          const isAdmin = await checkAdminRole(user.uid);
          if (isAdmin) {
            safeNext();
          } else {
            safeNext("/no-access");
          }
        } else {
          safeNext();
        }
      } else {
        safeNext();
      }
    };

    if (user) {
      handleAuthState(user);
    } else {
      onAuthStateChanged(auth, async (user) => {
        await handleAuthState(user);
      });
    }
  });

  return router;
};

export const addNewCalendarRoute = async (calendar) => {
  const newRoute = {
    path: `/calendar/${calendar.id}`,
    name: `Calendar${calendar.id}`,
    component: CalendarComponent,
    props: { calendarId: calendar.id, calendarName: calendar.name },
    meta: { requiresAuth: true },
  };
  router.addRoute(newRoute);
};

export default createRouterWithDynamicRoutes;
