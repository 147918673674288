<template>
    <div class="text-center">
      <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-black">
        Temps des Utilisateurs
      </h1>
  
      <div class="tabs">
        <button
          v-for="calendar in writableCalendars"
          :key="calendar.id"
          @click="setActiveCalendar(calendar.id)"
          :class="{ 'active-tab': calendar.id === activeCalendarId }"
          class="tab"
        >
          {{ calendar.name }}
        </button>
      </div>
  
      <div class="mb-4">
        <label for="startMonth" class="mr-2">Mois de début:</label>
        <select id="startMonth" v-model="startMonth">
          <option v-for="month in months" :key="month" :value="month">
            {{ month }}
          </option>
        </select>
  
        <label for="endMonth" class="ml-4 mr-2">Mois de fin:</label>
        <select id="endMonth" v-model="endMonth">
          <option v-for="month in months" :key="month" :value="month">
            {{ month }}
          </option>
        </select>
      </div>
  
      <div class="overflow-x-auto text-justify">
        <button
          @click="toggleUserDisplay"
          class="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-4"
        >
          {{
            showAllUsers
              ? "Afficher seulement les utilisateurs inscrits"
              : "Afficher tous les utilisateurs"
          }}
        </button>
        <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead class="bg-gray-50 dark:bg-gray-800">
            <tr>
              <th
                @click="setSort('name')"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              >
                Utilisateur
                <span v-if="sortKey === 'name'">
                  {{ sortAsc ? "▲" : "▼" }}
                </span>
              </th>
              <th
                v-for="month in filteredMonths"
                :key="month"
                @click="setSort(month)"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              >
                {{ month }}
                <span v-if="sortKey === month">
                  {{ sortAsc ? "▲" : "▼" }}
                </span>
              </th>
              <th
                @click="setSort('total')"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              >
                Total
                <span v-if="sortKey === 'total'">
                  {{ sortAsc ? "▲" : "▼" }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
            <tr v-for="userId in sortedUserIds" :key="userId">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">
                {{ getUserName(userId) }}
              </td>
              <td
                v-for="month in filteredMonths"
                :key="month"
                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
              >
                {{
                  userTimes[userId] && userTimes[userId][month]
                    ? userTimes[userId][month].toFixed(2)
                    : 0
                }}
                h
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                {{ getTotalTime(userId) }} h
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import { getAuth } from "firebase/auth";
  import { reactive, computed, toRefs, watch } from "vue";
  import {
    fetchEventsByCalendar,
    getCalendarNames,
    fetchUserRoles,
    getUserFullName,
  } from "../services/servicesPlanning";
  import { collection, getDocs, doc, getDoc } from "firebase/firestore";
  import { db } from "../firebaseConfig";
  
  export default {
    setup() {
      const state = reactive({
        userTimes: {},
        userNames: {},
        months: [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        startMonth: "Janvier",
        endMonth: "Décembre",
        allUserIds: [],
        showAllUsers: false,
        sortKey: 'name',
        sortAsc: false, // Sort descending by default
        writableCalendars: [],
        activeCalendarId: null,
        eventsByCalendar: {},
      });
  
      const auth = getAuth();
      const user = auth.currentUser;
  
      const fetchInitialData = async () => {
        if (user) {
          const roles = await fetchUserRoles(user.uid);
          const calendars = await getCalendarNames();
          state.writableCalendars = calendars.filter(
            (calendar) =>
              roles.includes("admin") ||
              roles.includes(`ecriture_${calendar.id}`)
          );
  
          if (state.writableCalendars.length > 0) {
            for (const calendar of state.writableCalendars) {
              const calendarDoc = await getDoc(doc(db, "calendars", calendar.id));
              if (calendarDoc.exists) {
                const calendarData = calendarDoc.data();
                calendar.startMonth = calendarData.startMonth || "Janvier";
                calendar.endMonth = calendarData.endMonth || "Décembre";
              }
              const events = await fetchEventsByCalendar(calendar.id);
              state.eventsByCalendar[calendar.id] = events;
            }
  
            const initialCalendarId = state.writableCalendars[0].id;
            await setActiveCalendar(initialCalendarId, true);
          }
  
          for (const calendar of state.writableCalendars) {
            const users = await fetchUsersWithAccess(calendar.id);
            state.allUserIds.push(...users);
          }
          state.allUserIds = [...new Set(state.allUserIds)];
        }
      };
  
      const calculateUserTimes = async (calendarId, events) => {
        const activeCalendar = state.writableCalendars.find(
          (calendar) => calendar.id === calendarId
        );
        if (!activeCalendar) return;
  
        // Reset userTimes for recalculation
        const newUserTimes = {};
  
        const startMonthIndex = state.months.indexOf(state.startMonth);
        const endMonthIndex = state.months.indexOf(state.endMonth);
        const isCrossYear = startMonthIndex > endMonthIndex;
  
        const today = new Date();
        const currentMonthIndex = today.getMonth();
        const currentYear = today.getFullYear();
  
        let startYear = currentYear;
        if (isCrossYear) {
          if (currentMonthIndex < startMonthIndex) {
            startYear--;
          }
        } else {
          if (currentMonthIndex < startMonthIndex || currentMonthIndex > endMonthIndex) {
            startYear--;
          }
        }
  
        for (const event of events) {
          for (const userId of event.bookedBy) {
            if (!state.userNames[userId]) {
              state.userNames[userId] = await getUserFullName(userId);
            }
  
            const startDate = new Date(event.start);
            const eventMonthIndex = startDate.getMonth();
            const eventYear = startDate.getFullYear();
  
            if (
              (eventYear === startYear && eventMonthIndex >= startMonthIndex) ||
              (eventYear === startYear + 1 && eventMonthIndex <= endMonthIndex)
            ) {
              const monthName = state.months[eventMonthIndex] + " " + eventYear;
  
              if (!newUserTimes[userId]) {
                newUserTimes[userId] = {};
              }
              if (!newUserTimes[userId][monthName]) {
                newUserTimes[userId][monthName] = 0;
              }
  
              const durationInHours =
                (new Date(event.end) - new Date(event.start)) / 1000 / 60 / 60;
              newUserTimes[userId][monthName] += durationInHours;
            }
          }
        }
  
        state.userTimes = newUserTimes;
      };
  
      const fetchUsersWithAccess = async (calendarId) => {
        const querySnapshot = await getDocs(collection(db, "users"));
        const userIds = [];
        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          const roles = data.roles || [];
          if (
            roles.includes("admin") ||
            roles.includes(`ecriture_${calendarId}`) ||
            roles.includes(`lecture_${calendarId}`)
          ) {
            userIds.push(doc.id);
            if (!state.userNames[doc.id]) {
              state.userNames[doc.id] = await getUserFullName(doc.id);
            }
          }
        }
        return userIds;
      };
  
      const toggleUserDisplay = () => {
        state.showAllUsers = !state.showAllUsers;
      };
  
      const getUserName = (userId) => {
        return state.userNames[userId] || "Loading...";
      };
  
      const getTotalTime = (userId) => {
        if (!state.userTimes[userId]) return 0;
        return Object.values(state.userTimes[userId])
          .reduce((total, time) => total + time, 0)
          .toFixed(2);
      };
  
      const setSort = (key) => {
        if (state.sortKey === key) {
          state.sortAsc = !state.sortAsc;
        } else {
          state.sortKey = key;
          state.sortAsc = false;
        }
      };
  
      const filteredUserIds = computed(() => {
        if (state.showAllUsers) {
          return state.allUserIds;
        } else {
          return Object.keys(state.userTimes);
        }
      });
  
      const sortedUserIds = computed(() => {
        return filteredUserIds.value.slice().sort((a, b) => {
          let aValue, bValue;
          if (state.sortKey === 'name') {
            aValue = getUserName(a);
            bValue = getUserName(b);
          } else if (state.sortKey === 'total') {
            aValue = getTotalTime(a);
            bValue = getTotalTime(b);
          } else {
            aValue = state.userTimes[a] ? state.userTimes[a][state.sortKey] || 0 : 0;
            bValue = state.userTimes[b] ? state.userTimes[b][state.sortKey] || 0 : 0;
          }
  
          if (aValue < bValue) return state.sortAsc ? -1 : 1;
          if (aValue > bValue) return state.sortAsc ? 1 : -1;
          return 0;
        });
      });
  
      const filteredMonths = computed(() => {
        const activeCalendar = state.writableCalendars.find(
          (calendar) => calendar.id === state.activeCalendarId
        );
        if (!activeCalendar) return [];
  
        const startMonthIndex = state.months.indexOf(state.startMonth);
        const endMonthIndex = state.months.indexOf(state.endMonth);
        const isCrossYear = startMonthIndex > endMonthIndex;
  
        const today = new Date();
        const currentMonthIndex = today.getMonth();
        const currentYear = today.getFullYear();
  
        let startYear = currentYear;
        if (isCrossYear) {
          if (currentMonthIndex < startMonthIndex) {
            startYear--;
          }
        } else {
          if (currentMonthIndex < startMonthIndex || currentMonthIndex > endMonthIndex) {
            startYear--;
          }
        }
  
        let filteredMonths = [];
        if (isCrossYear) {
          filteredMonths = [
            ...state.months.slice(startMonthIndex).map((month) => month + " " + startYear),
            ...state.months.slice(0, endMonthIndex + 1).map((month) => month + " " + (startYear + 1)),
          ];
        } else {
          filteredMonths = state.months
            .slice(startMonthIndex, endMonthIndex + 1)
            .map((month) => month + " " + startYear);
        }
  
        return filteredMonths;
      });
  
      const setActiveCalendar = async (calendarId, initial = false) => {
        state.activeCalendarId = calendarId;
        const activeCalendar = state.writableCalendars.find(
          (calendar) => calendar.id === calendarId
        );
        if (activeCalendar) {
          state.startMonth = activeCalendar.startMonth;
          state.endMonth = activeCalendar.endMonth;
        }
        const events = state.eventsByCalendar[calendarId] || [];
        await calculateUserTimes(calendarId, events);
        if (initial) {
          state.initialLoad = false;
        }
      };
  
      watch(
        () => state.writableCalendars,
        async (newWritableCalendars) => {
          if (state.initialLoad && newWritableCalendars.length > 0) {
            const initialCalendarId = newWritableCalendars[0].id;
            await setActiveCalendar(initialCalendarId, true);
          }
        },
        { immediate: true }
      );
  
      watch(
        () => [state.startMonth, state.endMonth],
        async () => {
          if (state.activeCalendarId && !state.initialLoad) {
            const events = state.eventsByCalendar[state.activeCalendarId] || [];
            await calculateUserTimes(state.activeCalendarId, events);
          }
        }
      );
  
      fetchInitialData();
  
      return {
        ...toRefs(state),
        toggleUserDisplay,
        getUserName,
        getTotalTime,
        setSort,
        sortedUserIds,
        setActiveCalendar,
        filteredMonths,
      };
    },
  };
  </script>
  
  <style>
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .tab {
    padding: 1rem;
    cursor: pointer;
  }
  
  .active-tab {
    font-weight: bold;
    border-bottom: 2px solid blue;
  }
  </style>
  