// store.js
import { createStore } from 'vuex';
import { getAuth } from 'firebase/auth';
import { getDoc, doc, getDocs, collection, updateDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

export default createStore({
  state: {
    currentUser: null,
    calendars: [],
    slots: [],
    users: [], // Ajouter un état pour stocker les utilisateurs
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setCalendars(state, calendars) {
      state.calendars = calendars;
    },
    setSlots(state, slots) {
      state.slots = slots;
    },
    updateSlot(state, updatedSlot) {
      const index = state.slots.findIndex(slot => slot.id === updatedSlot.id);
      if (index !== -1) {
        state.slots.splice(index, 1, updatedSlot);
      }
    },
    addSlot(state, newSlot) {
      state.slots.push(newSlot);
    },
    deleteSlot(state, slotId) {
      state.slots = state.slots.filter(slot => slot.id !== slotId);
    },
    setUsers(state, users) {
      state.users = users;
    },
  },
  actions: {
    async fetchCurrentUser({ commit }) {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          commit('setCurrentUser', { id: user.uid, ...docSnap.data() });
        }
      }
    },
    async fetchCalendars({ commit }) {
      const querySnapshot = await getDocs(collection(db, "calendars"));
      const calendars = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data.name) {
            calendars.push({
              id: doc.id,
              name: data.name,
            });
          }
        }
      });
      commit('setCalendars', calendars);
    },
    async fetchSlots({ commit }) {
      const querySnapshot = await getDocs(collection(db, "slots"));
      const slots = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        slots.push({ ...data, id: doc.id });
      });
      commit('setSlots', slots);
    },
    async fetchUsers({ commit }) { // Ajouter une action pour récupérer tous les utilisateurs
      const querySnapshot = await getDocs(collection(db, "users"));
      const users = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        users.push({ ...data, id: doc.id });
      });
      commit('setUsers', users);
    },
    async bookSlot({ commit}, { eventId, userId }) {
      const docRef = doc(db, "slots", eventId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const bookedBy = data.bookedBy || [];
        const maxInscrits = data.maxInscrits || 10;
        if (bookedBy.length >= maxInscrits) {
          throw new Error("Le nombre maximum d'inscrits pour ce créneau est atteint.");
        }
        if (!bookedBy.includes(userId)) {
          bookedBy.push(userId);
          await updateDoc(docRef, { bookedBy });
          commit('updateSlot', { ...data, bookedBy, id: eventId });
        } else {
          throw new Error("Vous avez déjà réservé ce créneau.");
        }
      }
    },
    async unbookSlot({ commit }, { eventId, userId }) {
      const docRef = doc(db, "slots", eventId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        const bookedBy = data.bookedBy || [];
        
        // Vérifier si l'utilisateur est inscrit
        if (bookedBy.includes(userId)) {
          // Retirer l'utilisateur de la liste
          const updatedBookedBy = bookedBy.filter((uid) => uid !== userId);
          
          // Mettre à jour Firestore avec la nouvelle liste
          await updateDoc(docRef, { bookedBy: updatedBookedBy });
          
          // Mettre à jour l'état du slot dans le store
          commit('updateSlot', { ...data, bookedBy: updatedBookedBy, id: eventId });
        } else {
          throw new Error("Vous n'êtes pas inscrit à ce créneau.");
        }
      }
    },    
    async addSlot({ commit }, newSlot) {
      const docRef = await addDoc(collection(db, "slots"), newSlot);
      commit('addSlot', { ...newSlot, id: docRef.id });
    },
    async deleteSlot({ commit }, slotId) {
      const docRef = doc(db, "slots", slotId);
      await deleteDoc(docRef);
      commit('deleteSlot', slotId);
    },
    async updateSlot({ commit }, updatedSlot) {
      const { id, ...slotData } = updatedSlot; // Extraire l'ID et construire l'objet sans ID
      const docRef = doc(db, "slots", id);
  
      try {
        await updateDoc(docRef, slotData);
        commit('updateSlot', { id, ...slotData }); // Commit sans inclure 'id' dans slotData
      } catch (error) {
        console.error("Erreur lors de la mise à jour du créneau:", error);
      }
    },
  }
});
