<template>
    <div>
      <div class="text-center">
        <div class="border border-gray-300 p-6 bg-white dark:bg-gray-800 shadow-lg rounded-md">
          <h2 class="text-4xl font-extrabold dark:text-white pb-1">
            Mon Planning
          </h2>
          <div v-if="upcomingEvents.length > 0" class="mb-8">
            <h3 class="text-2xl font-bold dark:text-white pb-4">À venir</h3>
            <div class="overflow-x-auto">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700 text-justify">
                <thead class="bg-gray-300 dark:bg-gray-950">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Date de début
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Date de fin
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Heure de début
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Heure de fin
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Lieu
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
                  <tr v-for="slot in upcomingEvents" :key="slot.id">
                    <td data-label="Nom" class="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">
                      {{ slot.title }}
                    </td>
                    <td data-label="Date de début" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {{
                        new Date(slot.start).toLocaleDateString('fr-FR', {
                          weekday: 'long',
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }).replace(/^\w/, (c) => c.toUpperCase())
                      }}
                    </td>
                    <td data-label="Date de fin" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {{
                        new Date(slot.end).toLocaleDateString('fr-FR', {
                          weekday: 'long',
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }).replace(/^\w/, (c) => c.toUpperCase())
                      }}
                    </td>
                    <td data-label="Heure de début" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {{
                        new Date(slot.start).toLocaleTimeString('fr-FR', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                      }}
                    </td>
                    <td data-label="Heure de fin" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {{
                        
                        new Date(slot.end).toLocaleTimeString('fr-FR', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                      }}
                    </td>
                    <td data-label="Lieu" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {{ slot.location || 'N/A' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div v-else class="text-gray-600 dark:text-gray-400 mb-8">
            Vous n'avez pas de créneaux à venir.
          </div>

          <!-- Tableau Affichage créneaux passés -->
          <div v-if="pastEvents.length > 0">
            <h3 class="text-2xl font-bold dark:text-white pb-4">Passés</h3>
            <div class="overflow-x-auto">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700 text-justify">
                <thead class="bg-gray-300 dark:bg-gray-950">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Date de début
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Date de fin
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Heure de début
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Heure de fin
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      Lieu
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
                  <tr v-for="slot in pastEvents" :key="slot.id">
                    <td data-label="Nom" class="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">
                      {{ slot.title }}
                    </td>
                    <td data-label="Date de début" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {{
                        new Date(slot.start).toLocaleDateString('fr-FR', {
                          weekday: 'long',
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }).replace(/^\w/, (c) => c.toUpperCase())
                      }}
                    </td>
                    <td data-label="Date de fin" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {{
                        new Date(slot.end).toLocaleDateString('fr-FR', {
                          weekday: 'long',
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }).replace(/^\w/, (c) => c.toUpperCase())
                      }}
                    </td>
                    <td data-label="Heure de début" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {{
                        new Date(slot.start).toLocaleTimeString('fr-FR', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                      }}
                    </td>
                    <td data-label="Heure de fin" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {{
                        new Date(slot.end).toLocaleTimeString('fr-FR', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                      }}
                    </td>
                    <td data-label="Lieu" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {{ slot.location || 'N/A' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else class="text-gray-600 dark:text-gray-400">
            Vous n'avez pas de créneaux passés.
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { collection, getDocs } from 'firebase/firestore';
  import { db } from '../firebaseConfig';
  import { getAuth } from 'firebase/auth';
  
  export default {
    data() {
      return {
        events: [],
        currentUserId: '',
      };
    },
    computed: {
      upcomingEvents() {
        const now = new Date();
        return this.events
          .filter((event) => event.bookedBy.includes(this.currentUserId) && new Date(event.start) >= now)
          .sort((a, b) => new Date(a.start) - new Date(b.start));
      },
      pastEvents() {
        const now = new Date();
        return this.events
          .filter((event) => event.bookedBy.includes(this.currentUserId) && new Date(event.start) < now)
          .sort((a, b) => new Date(b.start) - new Date(a.start));
      },
    },
    async mounted() {
      await this.fetchEvents();
      const user = getAuth().currentUser;
      if (user) {
        this.currentUserId = user.uid;
      }
    },
    methods: {
      async fetchEvents() {
        const querySnapshot = await getDocs(collection(db, 'slots'));
        this.events = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            start: data.start,
            end: data.end,
            title: data.title,
            calendar: data.calendar,
            bookedBy: data.bookedBy || [],
            location: data.location || '',
          };
        });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles personnalisés ici si nécessaire */
  </style>
  