<template>
  <div class="bg-white dark:bg-gray-800">
    <div class="flex justify-center h-screen">
      <div
        class="hidden bg-cover lg:block lg:w-2/3"
        style="
          background-image: url(https://images.unsplash.com/photo-1513128034602-7814ccaddd4e?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
        "
      >
        <div class="flex items-center h-full px-20 bg-gray-800 bg-opacity-40">
          <div>
            <!-- <h2 class="text-4xl font-bold text-white">Bienvenue sur l'espace bénévoles de l'Usine à</h2>
                        <p class="max-w-xl mt-3 text-gray-300">Lorem ipsum dolor sit, amet consectetur adipisicing elit. In autem ipsa, nulla laboriosam dolores, repellendus perferendis libero suscipit nam temporibus molestiae</p>
                         -->
          </div>
        </div>
      </div>

      <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
        <div class="flex-1">
          <div class="text-center">
            <!-- <h2 class="text-4xl font-bold text-center text-gray-700 dark:text-white"> -->

            <!-- debut LOGO UaC SVG -->
            <svg
              class="dark:text-slate-50 dark:fill-current"
              width="100%"
              height="100%"
              viewBox="0 0 2481 1270"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xml:space="preserve"
              xmlns:serif="http://www.serif.com/"
              style="
                fill-rule: evenodd;
                clip-rule: evenodd;
                stroke-linejoin: round;
                stroke-miterlimit: 2;
              "
            >
              <g
                id="Calque-1"
                serif:id="Calque 1"
                transform="matrix(4.16667,0,0,4.16667,0,0)"
              >
                <g transform="matrix(1,0,0,1,37.0547,245.612)">
                  <path
                    d="M0,23.882L0.037,21.444L0.037,2.439L0,0L3.896,0L3.859,2.439L3.859,20.843L10.725,20.843L10.725,23.882L0,23.882Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,54.5713,269.494)">
                  <path
                    d="M0,-23.882L-0.037,-21.444L-0.037,-2.439L0,0L-3.896,0L-3.859,-2.439L-3.859,-21.444L-3.896,-23.882L0,-23.882Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,62.3359,256.534)">
                  <path
                    d="M0,2.038L0,9.921L7.57,9.921L7.57,12.96L-3.859,12.96L-3.822,10.521L-3.822,-8.483L-3.859,-10.922L7.422,-10.922L7.422,-7.883L0,-7.883L0,-1.035L5.975,-1.035L5.975,2.038L0,2.038Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,86.1191,269.894)">
                  <path
                    d="M0,-24.282L-0.037,-21.844L-0.037,-6.045C-0.037,-2.171 -2.561,0 -6.605,0C-10.65,0 -13.1,-2.171 -13.1,-6.045L-13.1,-21.844L-13.137,-24.282L-9.24,-24.282L-9.277,-21.844L-9.277,-6.413C-9.277,-4.342 -8.498,-3.206 -6.605,-3.206C-4.676,-3.206 -3.859,-4.342 -3.859,-6.413L-3.859,-21.844L-3.896,-24.282L0,-24.282Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,99.4443,266.455)">
                  <path
                    d="M0,-17.804L0,0L1.781,0C3.006,0 3.637,-0.268 4.119,-0.635C5.047,-1.337 5.418,-2.372 5.418,-4.176L5.418,-13.628C5.418,-15.432 5.047,-16.468 4.119,-17.169C3.637,-17.536 3.006,-17.804 1.781,-17.804L0,-17.804ZM6.977,-19.474C8.572,-18.272 9.277,-16.534 9.277,-12.694L9.277,-5.112C9.277,-1.27 8.572,0.467 6.977,1.67C5.752,2.604 4.193,3.039 2.375,3.039L-3.859,3.039L-3.822,0.6L-3.822,-18.405L-3.859,-20.843L2.375,-20.843C4.193,-20.843 5.752,-20.409 6.977,-19.474"
                  />
                </g>
                <g transform="matrix(1,0,0,1,116.253,256.534)">
                  <path
                    d="M0,2.038L0,9.921L7.57,9.921L7.57,12.96L-3.859,12.96L-3.822,10.521L-3.822,-8.483L-3.859,-10.922L7.422,-10.922L7.422,-7.883L0,-7.883L0,-1.035L5.975,-1.035L5.975,2.038L0,2.038Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,141.637,269.494)">
                  <path
                    d="M0,-23.882L3.822,-23.882L-1.447,0L-5.678,0L-10.984,-23.882L-7.051,-23.882L-4.119,-9.687C-3.934,-8.617 -3.711,-7.148 -3.6,-6.112L-3.451,-6.112C-3.377,-7.148 -3.154,-8.617 -2.969,-9.687L0,-23.882Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,152.178,269.494)">
                  <path
                    d="M0,-23.882L-0.037,-21.444L-0.037,-2.439L0,0L-3.896,0L-3.859,-2.439L-3.859,-21.444L-3.896,-23.882L0,-23.882Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,159.933,256.534)">
                  <path
                    d="M0,2.038L0,9.921L7.57,9.921L7.57,12.96L-3.859,12.96L-3.822,10.521L-3.822,-8.483L-3.859,-10.922L7.422,-10.922L7.422,-7.883L0,-7.883L0,-1.035L5.975,-1.035L5.975,2.038L0,2.038Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,170.475,273.669)">
                  <path
                    d="M0,-8.05L3.934,-8.05L3.934,-4.208L1.67,0L-0.297,0L1.521,-4.208L0,-4.208L0,-8.05Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,187.395,266.455)">
                  <path
                    d="M0,-17.804L0,0L1.781,0C3.006,0 3.637,-0.268 4.119,-0.635C5.047,-1.337 5.418,-2.372 5.418,-4.176L5.418,-13.628C5.418,-15.432 5.047,-16.468 4.119,-17.169C3.637,-17.536 3.006,-17.804 1.781,-17.804L0,-17.804ZM6.977,-19.474C8.572,-18.272 9.277,-16.534 9.277,-12.694L9.277,-5.112C9.277,-1.27 8.572,0.467 6.977,1.67C5.752,2.604 4.193,3.039 2.375,3.039L-3.859,3.039L-3.822,0.6L-3.822,-18.405L-3.859,-20.843L2.375,-20.843C4.193,-20.843 5.752,-20.409 6.977,-19.474"
                  />
                </g>
                <g transform="matrix(1,0,0,1,204.202,256.534)">
                  <path
                    d="M0,2.038L0,9.921L7.57,9.921L7.57,12.96L-3.859,12.96L-3.822,10.521L-3.822,-8.483L-3.859,-10.922L7.422,-10.922L7.422,-7.883L0,-7.883L0,-1.035L5.975,-1.035L5.975,2.038L0,2.038Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,222.311,246.48)">
                  <path
                    d="M0,22.146C-1.373,21.077 -2.115,19.306 -2.115,14.797L-2.115,7.316C-2.115,2.839 -1.373,1.07 0,-0C0.965,-0.768 2.523,-1.269 4.49,-1.269C6.309,-1.269 7.793,-0.834 8.869,-0C10.391,1.169 11.021,3.107 11.021,6.848L11.021,6.915L7.162,6.915L7.162,6.848C7.162,4.243 7.162,1.938 4.453,1.938C1.744,1.938 1.744,4.243 1.744,6.581L1.744,15.533C1.744,18.038 1.744,20.209 4.453,20.209C7.236,20.209 7.236,17.97 7.236,14.764L7.236,14.697L11.096,14.697L11.096,14.764C11.096,19.44 10.279,21.077 8.906,22.146C7.904,22.914 6.457,23.415 4.49,23.415C2.523,23.415 0.965,22.914 0,22.146"
                  />
                </g>
                <g transform="matrix(1,0,0,1,249.738,269.894)">
                  <path
                    d="M0,-24.282L-0.037,-21.844L-0.037,-6.045C-0.037,-2.171 -2.561,0 -6.605,0C-10.65,0 -13.1,-2.171 -13.1,-6.045L-13.1,-21.844L-13.137,-24.282L-9.24,-24.282L-9.277,-21.844L-9.277,-6.413C-9.277,-4.342 -8.498,-3.206 -6.605,-3.206C-4.676,-3.206 -3.859,-4.342 -3.859,-6.413L-3.859,-21.844L-3.896,-24.282L0,-24.282Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,253.523,245.612)">
                  <path
                    d="M0,23.882L0.037,21.444L0.037,2.439L0,0L3.896,0L3.859,2.439L3.859,20.843L10.725,20.843L10.725,23.882L0,23.882Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,272.529,266.455)">
                  <path
                    d="M0,-17.804L0,0.6L0.037,3.039L-3.859,3.039L-3.822,0.6L-3.822,-17.804L-8.535,-17.804L-8.535,-20.843L4.713,-20.843L4.713,-17.804L0,-17.804Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,293.234,269.894)">
                  <path
                    d="M0,-24.282L-0.037,-21.844L-0.037,-6.045C-0.037,-2.171 -2.561,0 -6.605,0C-10.65,0 -13.1,-2.171 -13.1,-6.045L-13.1,-21.844L-13.137,-24.282L-9.24,-24.282L-9.277,-21.844L-9.277,-6.413C-9.277,-4.342 -8.498,-3.206 -6.605,-3.206C-4.676,-3.206 -3.859,-4.342 -3.859,-6.413L-3.859,-21.844L-3.896,-24.282L0,-24.282Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,300.876,258.638)">
                  <path
                    d="M0,-2.171L1.67,-2.171C4.564,-2.171 5.381,-3.875 5.381,-6.046L5.381,-6.112C5.381,-8.283 4.639,-9.987 1.447,-9.987L0,-9.987L0,-2.171ZM0,0.768L0,8.417L0.037,10.855L-3.859,10.855L-3.822,8.417L-3.822,-10.588L-3.859,-13.026L1.893,-13.026C7.867,-13.026 9.314,-9.92 9.314,-6.112L9.314,-6.046C9.314,-3.407 8.424,-1.103 5.937,0.033L9.945,10.855L5.863,10.855L2.264,0.768L0,0.768Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,317.804,256.534)">
                  <path
                    d="M0,2.038L0,9.921L7.57,9.921L7.57,12.96L-3.859,12.96L-3.822,10.521L-3.822,-8.483L-3.859,-10.922L7.422,-10.922L7.422,-7.883L0,-7.883L0,-1.035L5.975,-1.035L5.975,2.038L0,2.038Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,337.879,256.534)">
                  <path
                    d="M0,2.038L0,9.921L7.57,9.921L7.57,12.96L-3.859,12.96L-3.822,10.521L-3.822,-8.483L-3.859,-10.922L7.422,-10.922L7.422,-7.883L0,-7.883L0,-1.035L5.975,-1.035L5.975,2.038L0,2.038Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,356.319,266.455)">
                  <path
                    d="M0,-17.804L0,0.6L0.037,3.039L-3.859,3.039L-3.822,0.6L-3.822,-17.804L-8.535,-17.804L-8.535,-20.843L4.713,-20.843L4.713,-17.804L0,-17.804Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,372.943,266.455)">
                  <path
                    d="M0,-17.804L0,0L1.781,0C3.006,0 3.637,-0.268 4.119,-0.635C5.047,-1.337 5.418,-2.372 5.418,-4.176L5.418,-13.628C5.418,-15.432 5.047,-16.468 4.119,-17.169C3.637,-17.536 3.006,-17.804 1.781,-17.804L0,-17.804ZM6.977,-19.474C8.572,-18.272 9.277,-16.534 9.277,-12.694L9.277,-5.112C9.277,-1.27 8.572,0.467 6.977,1.67C5.752,2.604 4.193,3.039 2.375,3.039L-3.859,3.039L-3.822,0.6L-3.822,-18.405L-3.859,-20.843L2.375,-20.843C4.193,-20.843 5.752,-20.409 6.977,-19.474"
                  />
                </g>
                <g transform="matrix(1,0,0,1,389.761,256.534)">
                  <path
                    d="M0,2.038L0,9.921L7.57,9.921L7.57,12.96L-3.859,12.96L-3.822,10.521L-3.822,-8.483L-3.859,-10.922L7.422,-10.922L7.422,-7.883L0,-7.883L0,-1.035L5.975,-1.035L5.975,2.038L0,2.038Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,408.316,252.158)">
                  <path
                    d="M0,10.789C0.371,13.428 1.67,14.53 3.6,14.53C5.418,14.53 6.457,13.561 6.457,11.958C6.457,10.389 5.455,9.053 3.414,7.549L0.668,5.512C-2.041,3.608 -3.191,1.436 -3.191,-0.935C-3.191,-4.608 -0.482,-6.947 3.488,-6.947C7.422,-6.947 9.611,-4.643 10.094,-1.002L6.309,-0.534C6.123,-2.471 5.195,-3.74 3.377,-3.74C1.781,-3.74 0.705,-2.772 0.705,-1.102C0.705,0.435 1.781,1.671 3.711,3.04L6.42,5.077C9.055,6.915 10.354,9.185 10.354,11.758C10.354,15.466 7.719,17.736 3.563,17.736C-0.631,17.736 -3.229,15.365 -3.711,11.224L0,10.789Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,430.984,253.06)">
                  <path
                    d="M0,8.986L0,0.001C0,-2.405 0,-4.642 -2.746,-4.642C-5.455,-4.642 -5.455,-2.405 -5.455,0.001L-5.455,8.986C-5.455,11.39 -5.455,13.629 -2.746,13.629C0,13.629 0,11.39 0,8.986M-7.162,15.566C-8.535,14.497 -9.314,12.86 -9.314,8.251L-9.314,0.736C-9.314,-3.873 -8.535,-5.51 -7.162,-6.579C-6.086,-7.381 -4.602,-7.849 -2.746,-7.849C-0.891,-7.849 0.631,-7.381 1.67,-6.579C3.043,-5.51 3.859,-3.873 3.859,0.736L3.859,8.251C3.859,12.86 3.043,14.497 1.67,15.566C0.631,16.401 -0.891,16.835 -2.746,16.835C-4.602,16.835 -6.086,16.401 -7.162,15.566"
                  />
                </g>
                <g transform="matrix(1,0,0,1,438.566,245.612)">
                  <path
                    d="M0,23.882L0.037,21.444L0.037,2.439L0,0L3.896,0L3.859,2.439L3.859,20.843L10.725,20.843L10.725,23.882L0,23.882Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,456.081,269.494)">
                  <path
                    d="M0,-23.882L-0.037,-21.444L-0.037,-2.439L0,0L-3.896,0L-3.859,-2.439L-3.859,-21.444L-3.896,-23.882L0,-23.882Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,463.83,266.455)">
                  <path
                    d="M0,-17.804L0,0L1.781,0C3.006,0 3.637,-0.268 4.119,-0.635C5.047,-1.337 5.418,-2.372 5.418,-4.176L5.418,-13.628C5.418,-15.432 5.047,-16.468 4.119,-17.169C3.637,-17.536 3.006,-17.804 1.781,-17.804L0,-17.804ZM6.977,-19.474C8.572,-18.272 9.277,-16.534 9.277,-12.694L9.277,-5.112C9.277,-1.27 8.572,0.467 6.977,1.67C5.752,2.604 4.193,3.039 2.375,3.039L-3.859,3.039L-3.822,0.6L-3.822,-18.405L-3.859,-20.843L2.375,-20.843C4.193,-20.843 5.752,-20.409 6.977,-19.474"
                  />
                </g>
                <g transform="matrix(1,0,0,1,480.828,253.996)">
                  <path
                    d="M0,7.114L3.971,7.114L2.523,-0.602C2.338,-1.67 2.189,-3.14 2.078,-3.942L1.855,-3.942C1.781,-3.14 1.596,-1.67 1.41,-0.602L0,7.114ZM-1.521,15.498L-5.455,15.498L-0.631,-8.384L4.564,-8.384L9.389,15.498L5.529,15.498L4.527,10.22L-0.557,10.22L-1.521,15.498Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,496.974,258.638)">
                  <path
                    d="M0,-2.171L1.67,-2.171C4.564,-2.171 5.381,-3.875 5.381,-6.046L5.381,-6.112C5.381,-8.283 4.639,-9.987 1.447,-9.987L0,-9.987L0,-2.171ZM0,0.768L0,8.417L0.037,10.855L-3.859,10.855L-3.822,8.417L-3.822,-10.588L-3.859,-13.026L1.893,-13.026C7.867,-13.026 9.314,-9.92 9.314,-6.112L9.314,-6.046C9.314,-3.407 8.424,-1.103 5.937,0.033L9.945,10.855L5.863,10.855L2.264,0.768L0,0.768Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,513.933,269.494)">
                  <path
                    d="M0,-23.882L-0.037,-21.444L-0.037,-2.439L0,0L-3.896,0L-3.859,-2.439L-3.859,-21.444L-3.896,-23.882L0,-23.882Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,525.325,266.455)">
                  <path
                    d="M0,-17.804L0,0.6L0.037,3.039L-3.859,3.039L-3.822,0.6L-3.822,-17.804L-8.535,-17.804L-8.535,-20.843L4.713,-20.843L4.713,-17.804L0,-17.804Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,536.231,265.453)">
                  <path
                    d="M0,-21.211L2.783,-25.252L7.051,-25.252L2.598,-21.211L0,-21.211ZM0.631,-6.881L0.631,1.002L8.201,1.002L8.201,4.041L-3.229,4.041L-3.191,1.602L-3.191,-17.402L-3.229,-19.841L8.053,-19.841L8.053,-16.802L0.631,-16.802L0.631,-9.954L6.605,-9.954L6.605,-6.881L0.631,-6.881Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,73.9452,160.509)">
                  <path
                    d="M0,59.367C2.251,59.367 4.603,58.289 6.27,56.527L6.27,58.974L16.852,58.974L16.852,0L6.27,0L6.27,48.298C6.27,49.667 5.68,50.453 4.603,50.453C3.525,50.453 2.84,49.572 2.84,48.298L2.84,0L-7.741,0L-7.741,47.023C-7.741,55.646 -5.389,59.367 0,59.367"
                  />
                </g>
                <g transform="matrix(1,0,0,1,108.469,219.877)">
                  <path
                    d="M0,-59.659C-9.402,-59.659 -12.344,-57.795 -12.344,-49.274L-12.344,-38.205C-12.344,-34.09 -11.069,-31.446 -7.639,-29.582L-1.471,-26.254C0.197,-25.278 1.668,-24.295 1.668,-22.044L1.668,-10.48C1.668,-9.213 1.078,-8.325 0,-8.325C-1.078,-8.325 -1.762,-9.016 -1.762,-10.48L-1.762,-22.044L-12.344,-22.044L-12.344,-10.48C-12.344,-1.865 -9.402,0 0,0C9.11,0 12.242,-1.865 12.242,-10.48L12.242,-23.902C12.242,-27.332 11.164,-30.274 8.128,-32.036L0.488,-36.347C-0.976,-37.229 -1.762,-38.205 -1.762,-40.265L-1.762,-49.274C-1.762,-50.745 -1.078,-51.43 0,-51.43C1.078,-51.43 1.668,-50.549 1.668,-49.274L1.668,-39.574L12.242,-39.574L12.242,-49.274C12.242,-57.897 9.11,-59.659 0,-59.659"
                  />
                </g>
                <g transform="matrix(-1,0,0,1,262.671,-1.816)">
                  <rect x="126.045" y="149.008" width="10.581" height="8.426" />
                </g>
                <g transform="matrix(-1,0,0,1,262.671,75.372)">
                  <rect x="126.045" y="85.14" width="10.581" height="58.974" />
                </g>
                <g transform="matrix(1,0,0,1,158.822,219.483)">
                  <path
                    d="M0,-59.265C-2.251,-59.265 -4.508,-58.289 -6.27,-56.425L-6.27,-58.974L-16.852,-58.974L-16.852,0L-6.27,0L-6.27,-48.29C-6.27,-49.666 -5.586,-50.446 -4.508,-50.446C-3.43,-50.446 -2.84,-49.47 -2.84,-48.29L-2.84,0L7.741,0L7.741,-47.023C7.741,-55.638 5.484,-59.265 0,-59.265"
                  />
                </g>
                <g transform="matrix(1,0,0,1,196.479,188.338)">
                  <path
                    d="M0,3.423L0,-17.74C0,-26.355 -3.139,-28.118 -12.242,-28.118C-21.651,-28.118 -24.586,-26.261 -24.586,-17.74L-24.586,21.054C-24.586,29.677 -21.651,31.541 -12.242,31.541C-3.139,31.541 0,29.677 0,21.054L0,9.3L-10.581,9.3L-10.581,21.054C-10.581,22.329 -11.164,23.21 -12.242,23.21C-13.32,23.21 -14.011,22.525 -14.011,21.054L-14.011,3.423L0,3.423ZM-14.011,-17.74C-14.011,-19.204 -13.32,-19.889 -12.242,-19.889C-11.164,-19.889 -10.581,-19.007 -10.581,-17.74L-10.581,-4.705L-14.011,-4.705L-14.011,-17.74Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,321.328,160.217)">
                  <path
                    d="M0,59.659C9.11,59.659 12.242,57.795 12.242,49.179L12.242,33.995L1.668,33.995L1.668,49.179C1.668,50.454 1.078,51.335 0,51.335C-1.078,51.335 -1.762,50.643 -1.762,49.179L-1.762,10.385C-1.762,8.914 -1.078,8.23 0,8.23C1.078,8.23 1.668,9.111 1.668,10.385L1.668,24.397L12.242,24.397L12.242,10.385C12.242,1.763 9.11,0 0,0C-9.402,0 -12.344,1.865 -12.344,10.385L-12.344,49.179C-12.344,57.795 -9.402,59.659 0,59.659"
                  />
                </g>
                <g transform="matrix(1,0,0,1,352.913,195.478)">
                  <path
                    d="M0,-24.288L0,24.003L10.581,24.003L10.581,-23.014C10.581,-31.636 8.324,-35.263 2.84,-35.263C0.59,-35.263 -1.668,-34.28 -3.43,-32.416L-3.43,-48.291L-14.005,-48.291L-14.005,24.003L-3.43,24.003L-3.43,-24.288C-3.43,-25.657 -2.745,-26.444 -1.668,-26.444C-0.59,-26.444 0,-25.461 0,-24.288"
                  />
                </g>
                <g transform="matrix(1,0,0,1,381.166,219.877)">
                  <path
                    d="M0,-59.659C-9.402,-59.659 -12.344,-57.795 -12.344,-49.274L-12.344,-39.479L-1.762,-39.479L-1.762,-49.274C-1.762,-50.745 -1.078,-51.43 0,-51.43C1.078,-51.43 1.668,-50.549 1.668,-49.274L1.668,-37.52L-7.639,-32.131C-10.873,-30.274 -12.344,-27.82 -12.344,-23.414L-12.344,-11.66C-12.344,-3.139 -10.093,0 -4.996,0C-2.745,0 -0.393,-0.882 1.668,-2.841L1.668,-0.394L12.242,-0.394L12.242,-49.274C12.242,-57.897 9.11,-59.659 0,-59.659M1.668,-10.48C1.668,-9.213 1.078,-8.325 0,-8.325C-1.078,-8.325 -1.762,-9.016 -1.762,-10.48L-1.762,-23.414C-1.762,-24.885 -1.173,-25.861 -0.393,-26.356L1.668,-27.528L1.668,-10.48Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,415.591,233.298)">
                  <path
                    d="M0,-73.08C-2.251,-73.08 -4.508,-72.104 -6.27,-70.24L-6.27,-72.789L-16.852,-72.789L-16.852,0L-6.27,0L-6.27,-16.262C-4.901,-14.696 -2.352,-13.421 0.291,-13.421C5.389,-13.421 7.741,-17.048 7.741,-25.67L7.741,-60.838C7.741,-69.453 5.484,-73.08 0,-73.08M-2.84,-24.491C-2.84,-23.217 -3.43,-22.335 -4.508,-22.335C-5.586,-22.335 -6.27,-23.122 -6.27,-24.491L-6.27,-62.105C-6.27,-63.481 -5.586,-64.261 -4.508,-64.261C-3.43,-64.261 -2.84,-63.285 -2.84,-62.105L-2.84,-24.491Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,453.249,188.338)">
                  <path
                    d="M0,3.423L0,-17.74C0,-26.355 -3.132,-28.118 -12.242,-28.118C-21.651,-28.118 -24.586,-26.261 -24.586,-17.74L-24.586,21.054C-24.586,29.677 -21.651,31.541 -12.242,31.541C-3.132,31.541 0,29.677 0,21.054L0,9.3L-10.581,9.3L-10.581,21.054C-10.581,22.329 -11.164,23.21 -12.242,23.21C-13.32,23.21 -14.011,22.525 -14.011,21.054L-14.011,3.423L0,3.423ZM-14.011,-17.74C-14.011,-19.204 -13.32,-19.889 -12.242,-19.889C-11.164,-19.889 -10.581,-19.007 -10.581,-17.74L-10.581,-4.705L-14.011,-4.705L-14.011,-17.74Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,470.923,219.877)">
                  <path
                    d="M0,-59.659C-9.402,-59.659 -12.344,-57.795 -12.344,-49.274L-12.344,-39.479L-1.762,-39.479L-1.762,-49.274C-1.762,-50.745 -1.078,-51.43 0,-51.43C1.078,-51.43 1.668,-50.549 1.668,-49.274L1.668,-37.52L-7.639,-32.131C-10.873,-30.274 -12.344,-27.82 -12.344,-23.414L-12.344,-11.66C-12.344,-3.139 -10.093,0 -4.996,0C-2.745,0 -0.393,-0.882 1.668,-2.841L1.668,-0.394L12.242,-0.394L12.242,-49.274C12.242,-57.897 9.11,-59.659 0,-59.659M1.668,-10.48C1.668,-9.213 1.078,-8.325 0,-8.325C-1.078,-8.325 -1.762,-9.016 -1.762,-10.48L-1.762,-23.414C-1.762,-24.885 -1.173,-25.861 -0.393,-26.356L1.668,-27.528L1.668,-10.48Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,502.507,163.347)">
                  <path
                    d="M0,53.687L0,56.134L10.581,56.134L10.581,-2.84L0,-2.84L0,45.458C0,46.827 -0.59,47.613 -1.668,47.613C-2.745,47.613 -3.43,46.732 -3.43,45.458L-3.43,-2.84L-14.011,-2.84L-14.011,44.183C-14.011,52.806 -11.659,56.527 -6.27,56.527C-4.013,56.527 -1.668,55.449 0,53.687"
                  />
                </g>
                <g transform="matrix(1,0,0,1,528.509,160.509)">
                  <path
                    d="M0,58.974L2.447,34.876L2.84,34.876L5.389,58.974L16.357,58.974L10.581,27.135L15.869,0L4.996,0L2.745,21.061L2.447,21.061L0.488,0L-10.385,0L-5.287,27.135L-11.069,58.974L0,58.974Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,51.1223,156.796)">
                  <path
                    d="M0,53.273L-4.087,53.137C-5.762,53.056 -5.823,51.924 -5.823,50.392L-5.823,-9.321L-16.14,-9.321L-16.14,50.331C-15.943,58.825 -13.991,62.594 -4.087,62.594L0,62.261L0,53.273Z"
                  />
                </g>
                <g transform="matrix(1,0,0,1,0,14.33)">
                  <rect x="54.3" y="133.98" width="5.694" height="22.336" />
                </g>
                <g transform="matrix(1,0,0,1,0,-208.605)">
                  <rect
                    x="216.668"
                    y="243.197"
                    width="71.663"
                    height="26.837"
                  />
                </g>
                <g transform="matrix(1,0,0,1,251.337,123.655)">
                  <path
                    d="M0,52.684L5.911,49.308L5.911,95.776L36.408,95.742L36.299,-13.279C36.299,-38.041 27.297,-43.105 1.125,-43.105C-25.888,-43.105 -34.334,-37.756 -34.334,-13.279L-34.334,14.866L-3.938,14.866L-3.938,-13.279C-3.938,-17.495 -1.973,-19.468 1.125,-19.468C4.223,-19.468 5.911,-16.932 5.911,-13.279L5.911,20.492L-20.824,35.968C-30.111,41.316 -34.334,48.352 -34.334,61.015L-34.334,95.789L-3.938,95.748L-3.938,61.123C-3.938,56.907 -2.251,54.094 0,52.684"
                  />
                </g>
              </g>
            </svg>
            <!-- Fin LOGO UaC SVG -->

            <!-- <img src="../assets/logo/Logo baseline.png"></h2> -->

            <p class="mt-3 text-gray-500 dark:text-gray-300">
              Bienvenue sur l'espace réservé aux bénévoles !
            </p>
          </div>

          <div class="mt-8">
            <div>
              <label
                for="email"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-200"
                >Adresse email</label
              >
              <input
                v-model="email"
                type="email"
                name="email"
                id="email"
                placeholder="exemple@exemple.com"
                class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-800 dark:focus:border-violet-300 focus:ring-violet-300 focus:outline-none focus:ring focus:ring-opacity-40"
                required
              />
            </div>

            <div class="mt-6">
              <div class="flex justify-between mb-2">
                <label
                  for="password"
                  class="text-sm text-gray-600 dark:text-gray-200"
                  >Mot de passe</label
                >
              </div>
              <div class="relative">
                <input
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-800 dark:focus:border-violet-300 focus:border-violet-300 focus:outline-none focus:ring focus:ring-opacity-40"
                  required
                />
                <button
                  type="button"
                  @click="togglePasswordVisibility"
                  class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
                >
                  <svg
                    v-if="showPassword"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13.875 18.825A10.05 10.05 0 0112 19.5C7.305 19.5 3.262 16.047 1.5 12c.654-1.415 1.746-2.715 3.182-3.768m4.018-2.9A9.963 9.963 0 0112 4.5c4.695 0 8.738 3.453 10.5 7.5-.654 1.415-1.746 2.715-3.182 3.768M4.318 8.232L4.31 8.245M9 12a3 3 0 106 0 3 3 0 00-6 0z"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0zM2.458 12C3.732 7.943 7.305 4.5 12 4.5c1.562 0 3.042.362 4.318 1.018m3.182 3.768C20.268 8.285 19.176 6.985 17.74 5.932M12 19.5c-1.562 0-3.042-.362-4.318-1.018m-3.182-3.768C3.732 16.057 7.305 19.5 12 19.5c1.562 0 3.042-.362 4.318-1.018m3.182-3.768C20.268 15.715 19.176 14.415 17.74 13.362"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="mt-6">
              <button
                @click="login"
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-violet-900 rounded-md hover:bg-violet-500 focus:outline-none focus:bg-violet-400 focus:ring-opacity-50"
              >
                Se connecter
              </button>
              <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
            </div>

            <p class="mt-6 text-sm text-center text-gray-400">
              Vous avez oublié votre mot de passe ? Envoyer moi un email à
              <a
                href="mailto:c.guibert@usineachapeaux.fr"
                class="text-violet-900 dark:text-purple-200 focus:outline-none focus:underline hover:underline"
                >c.guibert@usineachapeaux.fr</a
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { redirectToFirstAccessibleCalendar } from "../services/servicesPlanning.js";
import { errorMessages } from "../services/errorMessages.js";
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,  // Ajouter cette ligne
      errorMessage: "",
    };
  },
  methods: {
    ...mapActions(['fetchCurrentUser', 'fetchCalendars', 'fetchUsers']),
    async checkIfLoggedIn() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        await redirectToFirstAccessibleCalendar(user.uid, (path) =>
          this.$router.push(path)
        );
      }
    },
    async login() {
      if (!this.email || !this.password) {
        this.errorMessage = "L'email et le mot de passe sont requis.";
        return;
      }
      const auth = getAuth();
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        localStorage.setItem("loginTime", Date.now());
        await this.fetchCurrentUser();
        await this.fetchCalendars();
        await this.fetchUsers();
        await redirectToFirstAccessibleCalendar(
          userCredential.user.uid,
          (path) => this.$router.push(path)
        );
      } catch (error) {
        this.errorMessage =
          errorMessages[error.code] ||
          "Une erreur est survenue lors de la connexion : " + error.message;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;  // Ajouter cette ligne
    },
  },
  async mounted() {
    await this.checkIfLoggedIn();
  },
};
</script>
